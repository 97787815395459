import React from "react";

function PlanningTransBarProgress({ transport }) {
  const { status, isAssign, driverStatus } = transport;

  const handleTransportStatus = (status, isAssign, driverStatus) => {
    if (status === "ACCEPTED" && isAssign === false) {
      return ["Envoyé"];
    } else if (
      status === "ACCEPTED" &&
      isAssign === true &&
      driverStatus === "PENDING"
    ) {
      return ["Envoyé", "Acquité"];
    } else if (
      status === "ACCEPTED" &&
      isAssign === true &&
      driverStatus === "ACCEPTED"
    ) {
      return ["Envoyé", "Acquité", "Début"];
    } else if (
      status === "IN_PROGRESS" &&
      isAssign === true &&
      driverStatus === "RETRIEVING_PATIENT"
    ) {
      return ["Envoyé", "Acquité", "Début", "En charge"];
    } else if (
      status === "IN_PROGRESS" &&
      isAssign === true &&
      driverStatus === "DEPOSITING_PATIENT"
    ) {
      return ["Envoyé", "Acquité", "Début", "En charge", "Déposé"];
    } else if (
      status === "ENDED" &&
      isAssign === true &&
      driverStatus === "ENDED"
    ) {
      return ["Envoyé", "Acquité", "Début", "En charge", "Déposé", "Fin"];
    } else {
      return "Status inconnu";
    }
  };

  const result = handleTransportStatus(status, isAssign, driverStatus);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#E1E4FF",
        padding: "0px 6px",
        borderRadius: "6px",
        color: "#001f5b",
        fontWeight: "bold",
        fontSize: "14px",
        marginBlock: "4px"
      }}
    >
      <span style={{ marginRight: "6px" }}>
        <img src="icons/planning_filter/clock.svg" />
      </span>
      {result.map((status, index) => (
        <React.Fragment key={index}>
          <span>{status}</span>
          {index < result.length - 1 && (
            <span style={{ margin: "0 8px" }}>➝</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default PlanningTransBarProgress;
