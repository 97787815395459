import * as React from "react";
import { Check } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@components/shadcn/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/shadcn/ui/popover";
import { useRegulator } from "../providers/RegulatorProvider";
import { useState } from "react";
import Palette from "@styles/Palette";
export function EmployeeCombobox({
  onSelect,
  triggerElement,
  filtered = false,
}) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const { employees } = useRegulator();

  // Filtrer les employés en fonction du type sélectionné
  const filteredEmployees = employees.filter((employee) =>
    // Si filtered est true, afficher uniquement les employés du type DEA
    filtered ? employee.type === "DEA" : true
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{triggerElement}</PopoverTrigger>
      <PopoverContent style={{ width: 200, padding: 0 }}>
        <Command>
          <CommandInput placeholder="Rechercher un employé..." />
          <CommandList>
            <CommandEmpty>Aucun employé trouvé.</CommandEmpty>
            <CommandGroup>
              {/* Ajouter l'option par défaut */}
              <CommandItem
                key="UNSET"
                value="Pas d'équipier"
                onSelect={() => {
                  setSelectedValue("Pas d'équipier");
                  setOpen(false);
                  onSelect({ id: null });
                }}
              >
                <Check
                  style={{
                    marginRight: 8,
                    height: 16,
                    width: 16,
                    opacity: selectedValue === "Pas d'équipier" ? 1 : 0,
                  }}
                />
                Pas d&apos;équipier
              </CommandItem>
              {filteredEmployees.map((employee) => {
                const value = `${employee.employeeId} - ${employee.lastName} ${employee.firstName}`;
                return (
                  <CommandItem
                    key={employee.id}
                    value={value}
                    onSelect={async (currentValue) => {
                      try {
                        await onSelect(employee);
                        setSelectedValue(
                          currentValue === selectedValue ? "" : value
                        );
                        setOpen(false);
                      } catch (error) {
                        console.error(
                          "Erreur lors de la sélection de l'employé:",
                          error
                        );
                      }
                    }}
                    className={
                      employee.type === "DEA"
                        ? "text-redDEA font-semibold"
                        : "text-text1 font-semibold"
                    }
                  >
                    <Check
                      style={{
                        marginRight: 8,
                        height: 16,
                        width: 16,
                        opacity: selectedValue === value ? 1 : 0,
                      }}
                    />
                    {employee.employeeId} - {employee.lastName}{" "}
                    {employee.firstName}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
