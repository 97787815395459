const Palette = {
  white: "white",
  black: "black",
  grey: "#EFEFEF",
  lightGrey: "#F8F8F8",
  darkGrey: "#8d8d8d",
  greyBackground: "#EDEDFC",
  greyItem: "#464F60",
  red: "#DB2C73",
  redText: "#D1293D",
  redItemBackground: "#FFEDEF",
  salmon: "#F28992",
  lightSalmon: "rgba(255, 187, 194, 0.1)",
  salmonTranslucent: "rgba(242,137,146,0.08)",
  redTranslucent: "rgba(219,44,115,0.08)",
  green: "#34C759",
  greenText: "#14804A",
  greenItemBackground: "#E1FCEF",
  lightGreen: "rgba(52,199,89,0.1)",
  greenTranslucent: "rgba(52,199,89,0.08)",
  blue: "#506BFF",
  blueSelectorBackground: "#DADBFF",
  blueSemiTranslucent: "rgba(80,107,255,0.2)",
  blueTranslucent: "rgba(55,125,255,0.08)",

  blueItemBackground: "#4951FF",
  blueBackground: "rgba(73, 81, 255, 0.1)",
  yellow: "#B4C028",
  lightYellow: "rgba(180,192,40,0.1)",
  yellowTranslucent: "rgba(180,192,40,0.2)",
  purple: "rgba(112, 0, 255, 1)",
  lightPurple: "rgba(112, 0, 255, 0.1)",
  purpleTranslucent: "rgba(206,167,255,0.2)",
  gray8: "rgba(222, 224, 243, 1)",
  gray9: "rgba(118, 118, 128, 0.12)",
  // HOSTAL FACTURATION
  background: "#FDFDFD",
  shadyGray: "#979797",
  shadyGray2: "#F7F9FC",
  bgGrey: "#F6F7FF",
  gainsboro: "#D9D9D9",
  electricIndigo: "#7000FF",
  electricIndigo10: "#7000FF10",
  electricIndigo50: "#7000FF50",
  chateauGreen: "#389D52",
  chateauGreen50: "#389D5250",
  cosmicLatte: "#EFFBF2",
  wewak: "#F28992",
  pink: "#DB2C73",
  pink60: "#DB2C7360",
  pink90: "#DB2C7390",
  strokGrayLight: "#F4F5FE",
  nightBlue: "#02196C",
  nightBlue60: "#02196C60",
  lightGray: "#D8D8D8",
  lightGray2: "#FAFAFF",
  skyBlue: "#506BFF",
  skyBlue10: "#4951FF10",
  skyBlue60: "#4951FF60",
  skyBlue90: "#4951FF90",
  skyBlue2: "#4951FF",
  borderV2: "#DEE0F3",
  formGrey: "#464F60",
  kingNacho: "#FFB800",
  whiteTranslucent: "rgba(255,255,255,0.5)",

  halfGrey: "#E0E0ED",
  // grey: '#A3A6A9',
  // darkGrey: '#494952',

  blueGrey: "#606EA6",

  lightBlue: "#F6F7FF",
  halfBlue: "#ECEEFD",
  // blue: '#506BFF',
  darkBlue: "#02196C",

  extraLightRed: "#FFFAFE",
  lightRed: "#FFDADE",
  halfRed: "#FFE8F1",
  // red: '#DB2C73',

  halfGreen: "#EFFBF2",
  // green: '#389D52',

  halfOrange: "rgba(255,102,69,0.14)",
  orange: "#FF6645",

  // Nouvelles couleurs
  redDEA: "#DB2C73",
  text1: "#02196C",
};

export default Palette;
