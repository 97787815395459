import React from "react";
import { useRegulator } from "../providers/RegulatorProvider";
import Palette from "../styles/Palette";

export const TeamMate = ({ teamMateId, index, style }) => {
  const { employees } = useRegulator();
  const teamMate = employees.find((emp) => emp.id === teamMateId);

  const getTextColorClass = () => {
    if (!teamMate) return "text-text1";
    return teamMate.type === "DEA" || index === 2
      ? "text-redDEA"
      : "text-text1";
  };

  return (
    <div
      className={`flex items-center italic font-semibold ${getTextColorClass()}`}
      style={style}
    >
      {teamMate ? (
        <>
          <span className="mr-1">{teamMate.employeeId}</span>
          <span className="mr-1">-</span>
          <span>
            {`${teamMate.lastName || ""} ${teamMate.firstName || ""}`}
          </span>
        </>
      ) : (
        <span className="opacity-60 italic text-grey">Non enregistré</span>
      )}
    </div>
  );
};
