import { MAP_CONFIG, MAP_STYLES } from "../constants/mapConfig";

const getVehicleColor = (user) => {
  switch (user.status) {
    case MAP_CONFIG.VEHICLE_STATES.AVAILABLE:
      return MAP_CONFIG.COLORS.AVAILABLE;
    case MAP_CONFIG.VEHICLE_STATES.UNAVAILABLE:
      return MAP_CONFIG.COLORS.UNAVAILABLE;
    case MAP_CONFIG.VEHICLE_STATES.ASSIGNED:
      return MAP_CONFIG.COLORS.ASSIGNED;
    case MAP_CONFIG.VEHICLE_STATES.INACTIVE:
      return MAP_CONFIG.COLORS.INACTIVE;
    default:
      return user.carType === "VSL"
        ? MAP_CONFIG.COLORS.VSL
        : MAP_CONFIG.COLORS.AMBULANCE;
  }
};

export const createVehicleMarker = (maps, user, options = {}) => {
  try {
    if (!maps || !user?.location) return null;

    const markerId = `marker-${user.id}`;

    // Nettoyer les anciens éléments
    const cleanup = () => {
      const existingMarker = document.getElementById(markerId);
      const existingInfo = document.getElementById(`${markerId}-info`);
      if (existingMarker) existingMarker.remove();
      if (existingInfo) existingInfo.remove();
    };
    cleanup();

    // Créer un marqueur invisible pour la position
    const marker = new maps.Marker({
      position: new maps.LatLng(
        user.location.latitude,
        user.location.longitude
      ),
      map: null,
      visible: false,
    });

    // Créer le conteneur du marqueur personnalisé
    const markerDiv = document.createElement("div");
    markerDiv.id = markerId;
    markerDiv.style.position = "absolute";
    markerDiv.style.width = "24px";
    markerDiv.style.height = "40px";
    markerDiv.style.cursor = "pointer";

    // Créer l'image avec rotation
    const img = document.createElement("img");
    img.src =
      user.carType === "VSL"
        ? "/icons/carGreyVertical.png"
        : "/icons/ambulanceGreyVertical.png";

    img.style.width = "100%";
    img.style.height = "100%";
    img.style.transform = `rotate(${user.heading || 0}deg)`;
    img.style.transformOrigin = "center center";
    img.style.transition = "none"; // Désactiver l'animation de transition

    markerDiv.appendChild(img);

    // Créer l'info-bulle
    const infoDiv = document.createElement("div");
    infoDiv.id = `${markerId}-info`;
    infoDiv.style.position = "absolute";
    infoDiv.style.backgroundColor = "white";
    infoDiv.style.padding = "4px 8px";
    infoDiv.style.borderRadius = "8px";
    infoDiv.style.boxShadow = "0 2px 4px rgba(0,0,0,0.2)";
    infoDiv.style.whiteSpace = "nowrap";
    infoDiv.style.transform = "translate(-50%, -220%)";
    infoDiv.style.zIndex = "1000";
    infoDiv.style.pointerEvents = "none";

    // Contenu de l'info-bulle
    const typeSpan = document.createElement("span");
    typeSpan.style.fontWeight = "bold";
    typeSpan.style.color = user.carType === "VSL" ? "#F4B400" : "#4285F4";
    typeSpan.textContent = user.carType === "AMBULANCE" ? "AMB" : user.carType;

    const separator = document.createTextNode(" ");

    const nameSpan = document.createElement("span");
    nameSpan.style.color = "#333333";
    nameSpan.style.fontWeight = "600";
    nameSpan.style.fontSize = "12px";
    nameSpan.textContent = user.name;

    infoDiv.appendChild(typeSpan);
    infoDiv.appendChild(separator);
    infoDiv.appendChild(nameSpan);

    // Créer l'overlay personnalisé
    const overlay = new maps.OverlayView();

    overlay.onAdd = function () {
      const panes = this.getPanes();
      panes.overlayImage.appendChild(markerDiv);
      panes.floatPane.appendChild(infoDiv);
    };

    overlay.draw = function () {
      const projection = this.getProjection();
      if (!projection) return;

      const position = projection.fromLatLngToDivPixel(marker.getPosition());
      if (!position) return;

      // Positionner le marker
      markerDiv.style.left = position.x - 12 + "px";
      markerDiv.style.top = position.y - 20 + "px";

      // Positionner l'info-bulle
      infoDiv.style.left = position.x + "px";
      infoDiv.style.top = position.y + "px";
    };

    overlay.onRemove = function () {
      if (markerDiv.parentNode) {
        markerDiv.parentNode.removeChild(markerDiv);
      }
      if (infoDiv.parentNode) {
        infoDiv.parentNode.removeChild(infoDiv);
      }
    };

    // Fonction de mise à jour de la rotation
    const updateRotation = (heading) => {
      img.style.transform = `rotate(${heading || 0}deg)`;
    };

    if (options.onClick) {
      markerDiv.addEventListener("click", () => options.onClick(user));
    }

    return {
      marker,
      overlay,
      updateRotation,
      markerDiv,
      infoDiv,
      user,
      id: markerId,
    };
  } catch (error) {
    console.error("Error creating vehicle marker:", error);
    return null;
  }
};

// Fonction utilitaire pour calculer la rotation de teinte pour les couleurs des véhicules
const getVehicleColorHueRotation = (user) => {
  const baseColor =
    user.carType === "VSL"
      ? MAP_CONFIG.COLORS.VSL
      : MAP_CONFIG.COLORS.AMBULANCE;
  const targetColor = getVehicleColor(user);

  // Convertir les couleurs hex en HSL pour calculer la rotation
  const baseHSL = hexToHSL(baseColor);
  const targetHSL = hexToHSL(targetColor);

  // Calculer la différence de teinte
  let hueRotation = targetHSL.h - baseHSL.h;

  // Normaliser la rotation entre -180 et 180 degrés
  if (hueRotation > 180) hueRotation -= 360;
  if (hueRotation < -180) hueRotation += 360;

  return `${hueRotation}deg`;
};

// Fonction utilitaire pour convertir une couleur hex en HSL
const hexToHSL = (hex) => {
  // Convertir hex en RGB
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = parseInt(result[1], 16) / 255;
  const g = parseInt(result[2], 16) / 255;
  const b = parseInt(result[3], 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return {
    h: h * 360,
    s: s * 100,
    l: l * 100,
  };
};

const PROXIMITY_THRESHOLD = 0.0001;

export const adjustOverlappingInfoDivs = (markerDatas) => {
  const positionGroups = new Map();

  // Regrouper par position exacte
  markerDatas.forEach((markerData) => {
    if (!markerData.marker || !markerData.infoDiv) return;

    const pos = markerData.marker.getPosition();
    const key = `${pos.lat()},${pos.lng()}`;

    if (!positionGroups.has(key)) {
      positionGroups.set(key, []);
    }
    positionGroups.get(key).push(markerData);
  });

  // Traiter chaque groupe
  positionGroups.forEach((group) => {
    if (group.length > 1) {
      // Trier AMB en premier
      group.sort((a, b) => {
        if (a.user.carType === "AMB" && b.user.carType !== "AMB") return -1;
        if (a.user.carType !== "AMB" && b.user.carType === "AMB") return 1;
        return 0;
      });

      // Ajuster les positions
      group.forEach((markerData, index) => {
        const { infoDiv } = markerData;
        if (!infoDiv) return;

        // Utiliser la position de base stockée
        const baseTop = parseInt(infoDiv.dataset.baseTop || infoDiv.style.top);
        const offset = index * 35;

        // Appliquer le nouveau top et z-index
        infoDiv.style.top = `${baseTop - offset}px`;
        infoDiv.style.zIndex = `${1000 + index}`;
      });
    }
  });
};
