import { useState, useEffect } from "react";
import _ from "lodash";

export const useMapZoom = (map, maps) => {
  const [isZooming, setIsZooming] = useState(false);

  const debouncedZoomEnd = _.debounce(() => {
    setIsZooming(false);
  }, 300);

  useEffect(() => {
    if (!map || !maps) return;

    const listener = maps.event.addListener(map, "zoom_changed", () => {
      setIsZooming(true);
      debouncedZoomEnd();
    });

    return () => {
      maps.event.removeListener(listener);
      debouncedZoomEnd.cancel();
    };
  }, [map, maps]);

  return isZooming;
};
