import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import firebase from "firebase/compat/app";
import React, { useMemo, useState } from "react";
import Loading from "../../components/Loading";
import LayoutMain from "../../layouts/main";

import PlanningBody from "@components/organisms/PlanningBody";
import PlanningFooter from "@components/organisms/PlanningFooter";
import PlanningHead from "@components/organisms/PlanningHead";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { dataDropdown } from "../../helpers/dataDropdown";
import useDataFromRef from "../../hooks/useDataFromRef";
import { useAuth } from "../../providers/Auth";
import {
  formatTransportDoc,
  Transports as RefTransports
} from "../../services/Firebase";

const moment = extendMoment(Moment);

export default () => {
  const { planningTransportStatusData } = dataDropdown;
  const [actualDate, setActualDate] = useState(moment());
  const [selected, setSelected] = useState([planningTransportStatusData[0]]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [transportType, setTransportType] = useState("");
  const auth = useAuth();
  const { uid } = firebase.auth().currentUser;
  const filters = useMemo(
    () =>
      RefTransports.where(
        auth.user.role === "HOSPITAL" ? "createdBy" : "regulatedBy",
        "==",
        uid
      )
        .where("startDate", ">=", actualDate.startOf("day").toDate())
        .where("startDate", "<=", actualDate.endOf("day").toDate())
        .orderBy("startDate"),
    [auth.user.role, actualDate]
  );

  const { data: defaultData, loading } = useDataFromRef({
    ref: filters,
    format: formatTransportDoc,
    listener: true,
    refreshArray: [actualDate]
  });

  const { data: bourseData } = useDataFromRef({
    ref: RefTransports.where("regulatedBy", "==", "BOURSE")
      .where("status", "==", "PENDING")
      .where("startDate", ">=", actualDate.startOf("day").toDate())
      .where("startDate", "<=", actualDate.endOf("day").toDate()),
    format: formatTransportDoc,
    listener: true,
    refreshArray: [actualDate]
  });

  const data = useMemo(() => {
    if (auth.user.role === "HOSPITAL") {
      return defaultData;
    }
    return [...defaultData, ...bourseData].sort(
      (a, b) => a.startDate - b.startDate
    );
  }, [auth.user.role, defaultData, bourseData]);

  const transports = useMemo(() => {
    if (!selected.length || selected[0].value[0] === "ALL") {
      return data;
    }
    return data.filter((d) => d.status === selected[0].value[0]);
  }, [selected, data]);

  const filteredTransports = useMemo(() => {
    let result = transports;

    if (selectedFilter?.isActive) {
      result = result.filter((d) => d.carType === selectedFilter.value);
    }

    if (searchValue !== "") {
      result = result.filter((d) => {
        return (d?.patient?.firstName ?? "")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    }
    if (transportType !== "") {
      if (transportType === "Retour simple") {
        result = result.filter(
          (d) => d?.reason === "BACK_TO_HOME" && !d?.isReturn
        );
      } else if (transportType === "Aller simple") {
        result = result.filter(
          (d) =>
            !d?.isReturn &&
            !d?.returnTransportId &&
            d?.reason !== "BACK_TO_HOME"
        );
      } else if (transportType === "Tous") {
        return result;
      }
    }

    return result;
  }, [selectedFilter, searchValue, transports, transportType]);

  return (
    <LayoutMain>
      <div id="zak" className="flex flex-col h-[100%] p-0 m-0">
        <PlanningHead
          selected={selected}
          setSelected={setSelected}
          dataTransportStatus={planningTransportStatusData}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          transports={transports}
          actualDate={actualDate}
          setActualDate={setActualDate}
          nbrTransports={data.length}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setTransportType={setTransportType}
        />
        <PlanningBody
          transports={filteredTransports}
          actualDate={actualDate}
          setActualDate={setActualDate}
        />
        <PlanningFooter transport={selected} />
      </div>
      {loading && <Loading />}
    </LayoutMain>
  );
};
