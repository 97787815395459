import React, { useState, useMemo, useEffect } from "react";
import {
  ChevronDown,
  ChevronUp,
  X,
  UserRound,
  UserPlusIcon,
  Search,
  Trash2,
  Plus,
} from "lucide-react";
import { Checkbox } from "../shadcn/ui/checkbox";
import { DataTable } from "../shadcn/ui/DataTable";
import { DataTablePagination } from "./DataTablePagination";
import { Button } from "../shadcn/ui/button";
import Palette from "../../styles/Palette";
import { RiPencilFill } from "react-icons/ri";
import { AiFillEye } from "react-icons/ai";
import { formatDate } from "../../services/Firebase";
import { useRegulator } from "@providers/RegulatorProvider";
import { toast } from "react-hot-toast";

export function EmployeeDataTable({ data, onEditEmployee }) {
  const [rowSelection, setRowSelection] = useState({});
  const [activeTab, setActiveTab] = useState("Tous");
  const [search, setSearch] = useState("");
  const { deleteEmployees } = useRegulator();

  const sortedData = useMemo(() => {
    return data.sort((a, b) => a.employeeId - b.employeeId);
  }, [data]);

  const filteredData = useMemo(() => {
    let filtered =
      activeTab === "Auxiliaires"
        ? sortedData.filter((employee) => employee.type === "AUX")
        : activeTab === "DEA"
        ? sortedData.filter((employee) => employee.type === "DEA")
        : sortedData;

    if (search.trim()) {
      const searchLower = search.toLowerCase().trim();
      filtered = filtered.filter(
        (employee) =>
          (employee.firstName || "").toLowerCase().includes(searchLower) ||
          (employee.lastName || "").toLowerCase().includes(searchLower) ||
          (employee.socialSecurity || "").toLowerCase().includes(searchLower)
      );
    }

    return filtered;
  }, [sortedData, activeTab, search]);

  // useEffect(() => {
  //   const selectedIds = Object.keys(rowSelection).filter(
  //     (key) => rowSelection[key]
  //   );
  //   console.log(
  //     selectedIds.length > 0
  //       ? `Tableau de lignes EMPLOYES mis à jour : ${selectedIds.join(", ")}`
  //       : "Aucune ligne sélectionnée"
  //   );
  //   onRowSelection(selectedIds);
  // }, [rowSelection, onRowSelection]);

  const SortableHeader = ({ column, title }) => {
    // if (filterDisabled) {
    //   return <div className="font-medium">{title}</div>;
    // }

    return (
      <div className="flex items-center">
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="w-full justify-start px-0 font-medium"
        >
          <span>{title}</span>
          <div className="ml-auto flex items-center">
            {column.getIsSorted() === "asc" ? (
              <ChevronUp className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ChevronDown className="h-4 w-4" />
            ) : null}
            {column.getIsSorted() && (
              <Button
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  column.clearSorting();
                }}
                className="p-0 h-8 w-8 ml-1"
              >
                <X className="h-4 w-4" />
              </Button>
            )}
          </div>
        </Button>
      </div>
    );
  };

  const handleDocumentClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const documentColumns = ["diploma", "drivingLicense", "healthCard", "rib"];
  const documentTitles = {
    diploma: "DIPLÔME",
    drivingLicense: "PERMIS",
    healthCard: "C.VITALE",
    rib: "RIB",
  };

  const documentCells = documentColumns.map((docType) => ({
    accessorKey: `documents.${docType}`,
    header: ({ column }) => (
      <SortableHeader column={column} title={documentTitles[docType]} />
    ),
    cell: ({ row }) => {
      const documentUrl = row.original.documents?.[docType];
      return (
        <div
          style={{
            backgroundColor: documentUrl
              ? Palette.greenItemBackground
              : Palette.redItemBackground,
            padding: "4px 8px",
            borderRadius: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "fit-content",
            color: documentUrl ? Palette.greenText : Palette.redText,
            cursor: documentUrl ? "pointer" : "default",
          }}
          className="font-medium"
          onClick={() => handleDocumentClick(documentUrl)}
        >
          <div
            style={{
              backgroundColor: documentUrl
                ? Palette.greenText
                : Palette.redText,
              margin: "0 4px",
              width: 6,
              height: 6,
              borderRadius: 2,
              marginRight: 5,
            }}
          />
          {documentUrl ? (
            <>
              OK
              <AiFillEye style={{ marginLeft: 5, width: 16, height: 16 }} />
            </>
          ) : (
            "Absent"
          )}
        </div>
      );
    },
    show: true,
  }));

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
      show: true, // Afficher cette colonne
    },
    {
      accessorKey: "type",
      header: ({ column }) => <SortableHeader column={column} title="TYPE" />,
      cell: ({ row }) => (
        <div className="flex flex-col  justify-center">
          {row.original.type === "DEA" ? (
            <>
              <UserPlusIcon className="h-6 text-nightBlue" />
              <span className="mt-1 text-xs text-red-600 font-medium italic">
                DEA
              </span>
            </>
          ) : (
            <>
              <UserRound className="h-6 w-6 text-nightBlue" />
              <span className="mt-1 text-xs text-gray-600 font-medium italic">
                AUX
              </span>
            </>
          )}
        </div>
      ),
      show: true, // Afficher cette colonne
    },
    {
      accessorKey: "id",
      header: "ID",
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
      enableHiding: true,
      enableSorting: false,
      show: false, // Ne pas afficher cette colonne
    },
    {
      accessorKey: "employeeId",
      header: ({ column }) => <SortableHeader column={column} title="ID" />,
      cell: ({ row }) => (
        <div className="text-nightBlue font-semibold">
          {row.getValue("employeeId")}
        </div>
      ),
      show: true, // Afficher cette colonne
    },
    {
      accessorKey: "gender",
      header: ({ column }) => <SortableHeader column={column} title="SEXE" />,
      cell: ({ row }) => {
        const gender = row.getValue("gender");
        return (
          <div className="text-nightBlue font-semibold">
            {gender === "male" ? "Mr" : "Mme"}
          </div>
        );
      },
      show: true, // Afficher cette colonne
    },
    {
      accessorKey: "lastName",
      header: ({ column }) => <SortableHeader column={column} title="NOM" />,
      cell: ({ row }) => (
        <div className="text-nightBlue font-semibold">
          {row.getValue("lastName")}
        </div>
      ),
      show: true, // Afficher cette colonne
    },
    {
      accessorKey: "firstName",
      header: ({ column }) => <SortableHeader column={column} title="PRÉNOM" />,
      cell: ({ row }) => (
        <div className="text-nightBlue font-semibold">
          {row.getValue("firstName")}
        </div>
      ),
      show: true, // Afficher cette colonne
    },
    {
      accessorKey: "birthDate",
      header: ({ column }) => (
        <SortableHeader column={column} title="DATE NAISSANCE" />
      ),
      cell: ({ row }) => (
        <div className="text-black font-semibold">
          {formatDate(row.getValue("birthDate"))}
        </div>
      ),
      show: true, // Afficher cette colonne
    },
    {
      accessorKey: "socialSecurity",
      header: ({ column }) => (
        <SortableHeader column={column} title="N°SÉCURITÉ SOCIAL" />
      ),
      cell: ({ row }) => (
        <div className="text-black font-semibold">
          {row.getValue("socialSecurity")}
        </div>
      ),
      show: true, // Afficher cette colonne
    },
    ...documentCells,
    {
      id: "actions",
      cell: ({ row }) => (
        <Button
          variant="ghost"
          className="h-6 w-6 p-0"
          style={{ backgroundColor: "rgba(73, 81, 255, 0.1)" }}
          onClick={() => onEditEmployee(row.original)}
        >
          <RiPencilFill
            className="h-4 w-4"
            style={{ color: Palette.blueItemBackground }}
          />
        </Button>
      ),
      show: true, // Afficher cette colonne
    },
  ];

  // Filtrer les colonnes à afficher
  const visibleColumns = columns.filter((column) => column.show);

  const handleDeleteSelected = async () => {
    try {
      await deleteEmployees(Object.keys(rowSelection));
      setRowSelection({});
      toast.success("Employ��(s) supprimé(s) avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression des employés:", error);
      toast.error("Erreur lors de la suppression des employés");
    }
  };

  const renderTabs = () => {
    const tabs = [
      { key: "Tous", count: data.length },
      {
        key: "Auxiliaires",
        count: data.filter((e) => e.type === "AUX").length,
      },
      { key: "DEA", count: data.filter((e) => e.type === "DEA").length },
    ];

    return (
      <div className="mb-4 flex space-x-4  font-medium">
        {tabs.map(({ key, count }) => (
          <button
            key={key}
            className={`pb-2 ${
              activeTab === key
                ? "border-b-2 border-nightBlue text-nightBlue font-bold"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab(key)}
          >
            {key}{" "}
            <span
              className={`ml-1 px-2 py-1 ${
                key === "Tous" ? "bg-blue-100" : "bg-gray-100"
              } text-blue-800 rounded-full text-xs`}
            >
              {count}
            </span>
          </button>
        ))}
      </div>
    );
  };

  const renderActionButtons = () => (
    <div className="flex justify-end">
      <button
        className="px-4 py-2 bg-gray-100 text-blue-600 rounded-md hover:opacity-80 flex items-center mr-2 max-w-56"
        onClick={() => onEditEmployee(null)}
      >
        <Plus className="mr-2" size={20} />
        Nouvel ambulancier
      </button>
    </div>
  );

  return (
    <div>
      <div className="flex items-center mb-4 justify-between">
        <div className="flex items-center">
          <button
            className={`p-2 text-gray-500 hover:text-gray-700 border rounded-md ${
              Object.keys(rowSelection).length === 0
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            onClick={handleDeleteSelected}
            disabled={Object.keys(rowSelection).length === 0}
          >
            <Trash2 size={20} />
          </button>
          <div className="mx-2 border rounded-md flex items-center max-w-96">
            <button className="p-2 text-gray-500 hover:text-gray-700 border-r-2">
              <Search className="text-gray-400" size={20} />
            </button>
            <input
              type="text"
              placeholder="Rechercher"
              className="w-40 pl-2 pr-4 py-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        {renderActionButtons()}
      </div>
      {renderTabs()}
      <div className="rounded-md border">
        <DataTable
          columns={visibleColumns}
          data={filteredData}
          pagination
          paginationComponent={DataTablePagination}
          onRowSelectionChange={setRowSelection}
          enableRowSelection={true}
          state={{ rowSelection }}
          enableSorting={true}
          enableColumnFilters={true}
          getRowId={(row) => row.id}
        />
      </div>
    </div>
  );
}
