import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "../lib/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary/80",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "bg-destructive text-destructive-foreground shadow hover:bg-destructive/80",
        outline: "text-foreground",
        pickedUp: "bg-nightBlue",
        notYet: "bg-lightGray",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

function Badge({ className, variant, ...props }) {
  const { message } = props;
  return (
    <div className="flex flex-col relative">
      <p style={{ width: "105px" }} className="absolute -top-5 text-xs italic px-1">
        {message}
      </p>
      <div className={cn(badgeVariants({ variant }), className)} {...props} />
    </div>
  );
}

export { Badge, badgeVariants };
