import { create } from "zustand";

export const useZustandStore = create()((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),
  isDriverModalOpen: [false, false],
  setIsDriverModalOpen: (isDriverModalOpen) => set({ isDriverModalOpen }),
  driverId: "",
  setDriverId: (driverId) => set({ driverId }),
  transports: [],
  setTransports: (transports) => set({ transports }),
  all_transports: [],
  setAll_transports: (all_transports) => set({ all_transports }),
  transport: null,
  setTransport: (transport) => set({ transport }),
  transport2: null,
  setTransport2: (transport2) => set({ transport2 }),
  transportsEnded: [],
  setTransportsEnded: (transportsEnded) => set({ transportsEnded }),
  transportsFromNotifications: [],
  setTransportsFromNotifications: (transportsFromNotifications) =>
    set({ transportsFromNotifications }),
  tripDistanceAndDuration: {},
  setTripDistanceAndDuration: (tripDistanceAndDuration) =>
    set({ tripDistanceAndDuration }),
  nextDriverTransports: [],
  setNextDriverTransports: (nextDriverTransports) =>
    set({ nextDriverTransports }),
  allNextTransports: [],
  setAllNextTransports: (allNextTransports) => set({ allNextTransports }),
  currentTransportyNotifTime: [],
  setCurrentTransportyNotifTime: (currentTransportyNotifTime) =>
    set({ currentTransportyNotifTime }),
  globalRef: {},
  setGlobalRef: (globalRef) => set({ globalRef }),
  isMapDragEnd: false,
  setIsMapDragEnd: (isMapDragEnd) => set({ isMapDragEnd }),
  reverseGeoCodingAdress: "",
  setReverseGeoCodingAdress: (reverseGeoCodingAdress) =>
    set({ reverseGeoCodingAdress }),
  isDrawerOpen: false,
  setIsDrawerOpen: (isDrawerOpen) => set({ isDrawerOpen }),
  refresh: false,
  setRefresh: (refresh) => set({ refresh }),
  driverHandleClick: null,
  setDriverHandleClick: (driverHandleClick) => set({ driverHandleClick }),
  availableDriver: null,
  setAvailableDriver: (availableDriver) => set({ availableDriver }),
  trackedVehiculeTransport: null,
  setTrackedVehiculeTransport: (trackedVehiculeTransport) =>
    set({ trackedVehiculeTransport }),
  transportId: null,
  setTransportId: (transportId) => set({ transportId }),
  regulatedTransport: null,
  setRegulatedTransport: (regulatedTransport) => set({ regulatedTransport }),
  isRegulationModalOpen: false,
  setIsRegulationModalOpen: (isRegulationModalOpen) =>
    set({ isRegulationModalOpen }),
  bourseTransport: null,
  setBourseTransport: (bourseTransport) => set({ bourseTransport }),
  isBourseModalOpen: false,
  setIsBourseModalOpen: (isBourseModalOpen) => set({ isBourseModalOpen }),
  planningTransportDetail: {},
  setPlanningTransportDetail: (planningTransportDetail) =>
    set({ planningTransportDetail })
}));
