import React from "react";

function PlanningTransportType({ returnTransportId }) {
  return (
    <div>
      <img
        width={20}
        src={
          returnTransportId
            ? "icons/planning_filter/planning_retour.svg"
            : "icons/planning_filter/planning_aller.svg"
        }
        alt=""
      />
    </div>
  );
}

export default PlanningTransportType;
