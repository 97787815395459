import React from "react";

const IconWrapper = ({ icon: Icon, size = 24, ...props }) => (
  <div
    style={{
      width: size,
      height: size,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Icon
      {...props}
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  </div>
);

export default IconWrapper;
