import TransportStatusSelector, {
  transportStatus,
} from "../../../components/Filters/TransportStatusSelector";
import DateRangeSelector from "../../../components/Filters/DateRangeSelector";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import Global from "../../../styles/Global";
import {
  formatTransportDoc,
  Transports as RefTransports,
  Transports,
} from "../../../services/Firebase";
import firebase from "firebase/compat/app";
import useDataFromRef from "../../../hooks/useDataFromRef";
import Loading from "../../../components/Loading";
import AdvancedTable from "../../../components/AdvancedTable";
import { useAuth } from "../../../providers/Auth";
import Dropdown from "../../../components/Dropdown";
import AttributeSelector from "../../../components/AttributeSelector";
import Colors from "../../../styles/Colors";
import { STATUS } from "../../../helpers/status";
import LayoutMain from "../../../layouts/main";
import Palette from "../../../styles/Palette";
import Input from "../../../components/Input";
import { RiArrowRightLine, RiDraftLine } from "react-icons/ri";
import VehiclePickerGroupButton from "../../../components/Filters/VehiclePickerGroupButton";
import StatusPickerGroupButton from "../../../components/Filters/StatusPickerGroupButton";
import DatePickerButton from "../../../components/Filters/DatePickerButton";
import invoiceStatus from "../../../constants/tripStatus";
import vehicles from "../../../constants/vehicles";
import {
  containerFlexCenterCenter,
  containerFlexItem,
  containerFlexRowStart,
  containerFlexRowStartStart,
  containerFlexStartStart,
} from "../../../styles/main";
import IconButton from "../../../components/IconButton";
import Fonts from "../../../styles/fonts";
import { FaAmbulance, FaCarSide } from "react-icons/fa";
import ItinaryLine from "../../../components/ItinaryLine";
import tripStatus from "../../../constants/tripStatus";
import { useHistory } from "react-router-dom";

export function TransportList({
  transports,
  containerStyle,
  onClick,
  selected,
}) {
  const history = useHistory();
  // const { getAttributeForTransport } = useAuth();

  const [selectedTransport, setSelectedTransport] = useState(null);

  function onSelectTransport(transport) {
    if (selectedTransport?.id === transport?.id) {
      setSelectedTransport(null);
    } else {
      setSelectedTransport(transport);
    }
  }

  return (
    <div
      className={"container flex"}
      style={{
        ...Global.containerItem,
        ...containerStyle,
      }}
    >
      <AdvancedTable
        containerStyle={{
          width: "100%",
          transition: "all 0.15s ease-in-out",
          paddingBottom: selected ? 134 + 60 : 30,
        }}
        {...{
          data: transports,
          selected,
          setSelected: onSelectTransport,

          columns: [
            {
              title: "DEM",
              render: (transport) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      gap: 5,
                    }}
                  >
                    <div>
                      <div style={{ marginBottom: 5 }}>
                        {transport?.demDate
                          ? moment(transport?.demDate).format("HH:mm")
                          : "DEM inconnu"}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: "PATIENT",
              render: (transport) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      gap: 5,
                    }}
                  >
                    <div>
                      <div style={{ marginBottom: 5 }}>
                        {transport?.patient?.gender === "male"
                          ? "M." + " "
                          : "Mme" + " "}
                        {transport?.patient?.firstName + " " ||
                          "Patient inconnu"}
                        {transport?.patient?.lastName || "Patient inconnu"}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: "DEP",
              render: (transport) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      gap: 5,
                    }}
                  >
                    <div>
                      <div style={{ marginBottom: 5 }}>
                        {moment(transport?.startDate).format("HH:mm") ||
                          "Heure de prise en charge inconnu"}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: "LIEU DE DEPART",
              render: (transport) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      gap: 5,
                    }}
                  >
                    <div>
                      <div style={{ marginBottom: 5 }}>
                        {transport?.from?.name || "Départ inconnu"}
                      </div>
                      <div style={{}}>
                        {transport?.from?.postalCode +
                          " " +
                          transport?.from?.city || "Départ inconnu"}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: "RDV",
              render: (transport) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      gap: 5,
                    }}
                  >
                    <div>
                      <div style={{ marginBottom: 5 }}>
                        {moment(transport?.endDate).format("HH:mm") ||
                          "Heure de RDV inconnu"}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: "LIEU D'ARRIVEE",
              render: (transport) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      gap: 5,
                    }}
                  >
                    <div>
                      <div style={{ marginBottom: 5 }}>
                        {transport?.to?.name || "Départ inconnu"}
                      </div>
                      <div style={{}}>
                        {transport?.to?.postalCode +
                          " " +
                          transport?.to?.city || "Départ inconnu"}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: "DATE DU TRANSPORT",
              render: (transport) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      gap: 5,
                    }}
                  >
                    <div>
                      <div style={{ marginBottom: 5 }}>
                        {moment(transport?.endDate).format(
                          "dddd, Do MMMM YYYY"
                        ) || "Heure de prise en charge inconnu"}
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: "TYPE DE VEHICULE",
              render: (transport) => {
                const Icon =
                  transport.carType === "AMBULANCE" ? FaAmbulance : FaCarSide;
                return <Icon size={24} />;
              },
            },
            {
              title: "A/AR/R",
              render: (transport) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      gap: 5,
                    }}
                  >
                    <div>
                      {!transport.goToTransportId ? (
                        <div style={{}}>
                          A
                          {!!transport?.returnTransportId &&
                            (transport?.status === "PENDING" ||
                              transport?.status === "ACCEPTED") &&
                            transport?.isAssign !== true &&
                            "R"}
                          {/* {!transport?.returnTransportId ? " Simple" : ""} */}
                        </div>
                      ) : (
                        <div
                          style={{
                            color: Palette.red,
                          }}
                        >
                          R
                        </div>
                      )}
                    </div>
                  </div>
                );
              },
            },
            {
              title: "ETAT",
              render: (transport) => {
                console.log("STATUS OF trip :", transport?.status);
                const status = STATUS[transport.status];
                return (
                  <div
                    style={{
                      ...containerFlexItem,
                      ...containerFlexCenterCenter,
                      alignSelf: "center",
                      width: "100%",
                      height: "100%",
                      backgroundColor: status?.style.backgroundColor,
                      border: "none",
                      borderRadius: 0,
                      ...Fonts.medium(12, status?.style.color),
                    }}
                  >
                    {status?.name}
                  </div>
                );
              },
            },
            {
              title: "GO",
              render: (transport) => (
                <IconButton
                  onClick={() => {
                    // transport.stopPropagation();
                    console.log("transport", transport);
                    history.push(`?transport=${transport.id}`);
                  }}
                  icon={RiArrowRightLine}
                  containerStyle={{
                    backgroundColor: Palette.lightBlue,
                    height: 35,
                    alignSelf: "center",
                    borderRadius: 5,
                  }}
                  iconProps={{}}
                />
              ),
              config: {
                row: {
                  style: {
                    width: 50,
                  },
                },
              },
            },
          ],
        }}
      />
    </div>
  );
}

export const carTypes = [
  { name: "Tout les types", value: "ALL" },
  { name: "Ambulances", value: "AMBULANCE" },
  { name: "VSL", value: "VSL" },
];

function CarTypeSelector({
  containerStyle,
  selectedCarType,
  setSelectedCarType,
}) {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <div
      className={"flex"}
      style={{
        ...Global.item,
        position: "relative",
        maxWidth: 320,
        ...containerStyle,
      }}
    >
      <div
        className={"flex containerRowCenter"}
        onClick={() => setDropdownVisible((v) => !v)}
        style={{ cursor: "pointer" }}
      >
        <div>{selectedCarType.name}</div>
        <img
          src={"/icons/chevronLeft.png"}
          style={{
            position: "absolute",
            top: "calc(50% - 12px)",
            bottom: 0,
            right: 10,
            alignItems: "center",
            width: 24,
            height: 24,
            transform: "rotate(-90deg)",
          }}
        />
      </div>
      <Dropdown visible={dropdownVisible}>
        {carTypes.map((carType, i) => {
          const isLast = i === transportStatus.length - 1;
          return (
            <div
              onClick={() => setSelectedCarType(carType)}
              className={"containerRow"}
              style={{
                alignItems: "center",
                cursor: "pointer",
                marginBottom: isLast ? 0 : 5,
              }}
              key={carType.value}
            >
              <input
                style={{ marginRight: 5, width: 24, height: 24 }}
                type="checkbox"
                checked={selectedCarType.value === carType.value}
              />
              {carType.name}
            </div>
          );
        })}
      </Dropdown>
    </div>
  );
}

export default () => {
  const auth = useAuth();
  const { uid } = firebase.auth().currentUser;
  const [selectedCarType, setSelectedCarType] = useState(carTypes[0]);
  const [selectedStatus, setSelectedStatus] = useState(transportStatus[0]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().endOf("month"));
  const [HFselectedStatus, setHFSelectedStatus] = React.useState(
    Object.keys(invoiceStatus)
  );
  const [HFselectedVehicle, setHFSelectedVehicle] = React.useState(
    Object.keys(vehicles)
  );
  const [dummyPatientInput, setDummyPatientInput] = React.useState("");

  useEffect(() => {
    if (!moment.isMoment(startDate)) {
      setStartDate(moment(startDate));
    }
  }, [startDate]);

  const filters = useMemo(
    () =>
      RefTransports.where(
        auth.user.role === "HOSPITAL" ? "createdBy" : "regulatedBy",
        "==",
        uid
      )
        .where("startDate", ">=", moment(startDate).startOf("day").toDate())
        .where("startDate", "<=", moment(startDate).endOf("day").toDate())
        .orderBy("startDate"),
    [auth.user.role, startDate, endDate]
  );

  const { data, loading } = useDataFromRef({
    ref: filters,
    format: formatTransportDoc,
    listener: true,
    refreshArray: [startDate],
  });

  const transports = useMemo(() => {
    let tmp = data;
    if (selectedCarType && selectedCarType?.value !== "ALL") {
      tmp = tmp.filter((t) => t.carType === selectedCarType.value);
    }
    if (selectedStatus && selectedStatus?.value[0] !== "ALL") {
      tmp = tmp.filter((t) => t.status.includes(selectedStatus.value[0]));
    } else {
      tmp = tmp.filter((t) => !(t.status === "REFUSED" && t.createdBy === uid));
    }
    return tmp;
  }, [data, selectedStatus, selectedCarType]);

  return (
    <LayoutMain>
      <div className="bg-slate-100 rounded-t-3xl flex mb-6 justify-between p-6 max-h-20 items-center">
        <h1 className="text-2xl font-bold">Régulation</h1>
      </div>
      <div
        style={{
          boxSizing: "border-box",
          paddingTop: 20,
        }}
      >
        <div
          style={{
            // ...containerFlexRowStartStart,
            flexWrap: "wrap",
            marginBottom: 30,
            gap: 10,
          }}
        >
          <div
            style={{
              ...containerFlexStartStart,
              padding: 30,
              height: "100%",
            }}
          >
            <div style={{ flex: 1 }}>
              <div
                style={{
                  ...containerFlexRowStartStart,
                  flexWrap: "wrap",
                  alignItems: "center",
                  marginBottom: 30,
                  gap: 10,
                }}
              >
                {/* {search?.length < 1 && ( */}
                {/* <DateRangeSelector
              containerStyle={{ marginRight: 20 }}
              {...{ startDate, setStartDate, endDate, setEndDate }}
            /> */}
                <DatePickerButton
                  value={moment(startDate).toDate()}
                  onChange={setStartDate}
                />
                {/* <DatePickerButton
                value={HFselectedDate}
                onChange={setHFSelectedDate}
              /> */}
                {/* )} */}
                {/* Status Picker */}
                <StatusPickerGroupButton
                  value={HFselectedStatus}
                  onChange={setHFSelectedStatus}
                />
                {/* Vehicle Picker */}
                <VehiclePickerGroupButton
                  value={HFselectedVehicle}
                  onChange={setHFSelectedVehicle}
                />
                {/* Search bar */}
                <Input
                  value={dummyPatientInput}
                  onChange={setDummyPatientInput}
                  iconLeft={() => {}}
                  leftStyle={{
                    color: Palette.blueGrey,
                  }}
                  placeholder={"Nom du patient"}
                  placeholderStyle={{}}
                  height={35}
                  style={{
                    height: 35,
                    borderRadius: 5,
                    overflow: "hidden",
                    minWidth: 180,
                    borderColor: Palette.blueGrey,
                  }}
                />
              </div>
            </div>
            {/* <TransportStatusSelector
            containerStyle={{ marginRight: 20 }}
            {...{ selectedStatus, setSelectedStatus }}
          />
          <DateRangeSelector
            containerStyle={{ marginRight: 20 }}
            {...{ startDate, setStartDate, endDate, setEndDate }}
          />
          <CarTypeSelector {...{ selectedCarType, setSelectedCarType }} /> */}
          </div>
          <>
            <TransportList {...{ transports }} />
            {loading && <Loading absolute />}
          </>
        </div>
      </div>
    </LayoutMain>
  );
};
