import React from "react";
function PlanningTransportState({ status }) {
  const planningTransportStatus = [
    {
      name: "A réguler",
      status: "ACCEPTED",
      imgTop: "icons/planning_filter/reguler.svg"
    },
    {
      name: "Annulé",
      status: "CANCELED",
      imgTop: "icons/planning_filter/annuler.svg"
    },
    {
      name: "En cours",
      status: "IN_PROGRESS",
      imgTop: "icons/planning_filter/encours.svg"
    },
    {
      name: "Terminé",
      status: "ENDED",
      imgTop: "icons/planning_filter/termine.svg"
    },
    {
      name: "Attribué",
      status: "ASSIGNED",
      imgTop: "icons/planning_filter/attribue.svg"
    },
    {
      name: "Bourse",
      status: "BOURSE",
      imgTop: "icons/planning_filter/bourse.svg"
    },
    {
      name: "Nouveau",
      status: "PENDING",
      imgTop: "icons/planning_filter/nouveau.svg"
    }
  ];
  return (
    <img
      width={18}
      src={
        planningTransportStatus.find((item) => item.status === status).imgTop ||
        ""
      }
      alt=""
    />
  );
}

export default PlanningTransportState;
