"use client";

import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@components/shadcn/ui/card";
import { Button } from "@components/shadcn/ui/button";
import { Input } from "@components/shadcn/ui/input";
import { Label } from "@components/shadcn/ui/label";
import { TeamMate } from "@components/TeamMate";
import {
  CarIcon,
  AmbulanceIcon,
  EyeIcon,
  EyeOffIcon,
  Trash2Icon,
  ChevronDown,
} from "lucide-react";
import { Badge } from "@components/shadcn/ui/badge";
import IconWrapper from "@components/IconWrapper";
import useDataFromRef from "@hooks/useDataFromRef";
import { Employees, Users } from "@services/Firebase";
import firebase from "firebase/compat/app";
import { toast } from "react-hot-toast";
import { EmployeeCombobox } from "../EmployeeCombobox";
import { useRegulator } from "@providers/RegulatorProvider";

function VehiculeForm({ onClose, vehicle = {} }) {
  const { uid } = firebase.auth().currentUser || {};
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    employees,
    deleteVehicles,
    addVehicle,
    updateVehicle,
    peekNextVehicleId,
  } = useRegulator();
  const [displayId, setDisplayId] = useState("...");

  // const isDevelopment = process.env.REACT_APP_USE_FIREBASE_EMULATOR === "true";
  const isDevelopment = false;

  const defaultFormData = isDevelopment
    ? {
        mail: "test@test.com",
        phoneNumber: "0612345678",
        password: "123456",
        name: "",
        teamMate1Id: null,
        teamMate2Id: null,
        carType: "VSL",
      }
    : {
        mail: "",
        phoneNumber: "",
        password: "",
        name: "",
        teamMate1Id: null,
        teamMate2Id: null,
        carType: "VSL",
      };

  // Effet simplifié qui ne s'exécute qu'une seule fois
  useEffect(() => {
    if (vehicle?.id) {
      setDisplayId(vehicle.vehicleId);
    } else {
      setDisplayId(peekNextVehicleId());
    }
  }, [vehicle?.id]);

  const [formData, setFormData] = useState(defaultFormData);
  const setCarType = (carType) => {
    setFormData((prev) => ({ ...prev, carType }));
  };

  useEffect(() => {
    if (vehicle) {
      setFormData((prevData) => ({
        ...prevData,
        ...vehicle,
      }));
    }
  }, [vehicle]);

  const { data: employeesData, loading: employeesLoading } = useDataFromRef({
    ref: Employees.where("createdBy", "==", uid),
    listener: true,
  });

  const employeesDEA = employeesData.filter(
    (employee) => employee.type === "DEA"
  );

  const formatImmatriculation = (value) => {
    value = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
    if (value.length > 2) value = value.slice(0, 2) + "-" + value.slice(2);
    if (value.length > 6) value = value.slice(0, 6) + "-" + value.slice(6);
    return value.slice(0, 9);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "name") {
      setFormData((prev) => ({ ...prev, [id]: formatImmatriculation(value) }));
    } else if (id === "phoneNumber") {
      const phoneNumber = value.replace(/\D/g, "").slice(0, 10);
      setFormData((prev) => ({ ...prev, [id]: phoneNumber }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }
  };

  const handleSelectChange = (id, value) => {
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleDelete = async () => {
    try {
      deleteVehicles([vehicle.id]);
      toast.success("Véhicule supprimé avec succès !");
      console.log("Suppression du véhicule");
      onClose();
    } catch (error) {
      console.error("Erreur lors de la suppression:", error);
      toast.error(`Erreur : ${error.message}`);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const validateForm = () => {
    const fieldPlaceholders = {
      carType: "Type de véhicule",
      name: "Plaque d'immatriculation",
      phoneNumber: "Numéro de téléphone",
      mail: "Email",
      password: "Mot de passe",
      teamMate1Id: "Equipier 1",
      teamMate2Id: "Equipier 2",
    };

    const requiredFields = [
      "carType",
      "name",
      "phoneNumber",
      "mail",
      "password",
      // "teamMate1Id",
    ];
    console.log(formData.name);

    // if (formData.carType === "AMBULANCE") {
    //   // requiredFields.push("teamMate2Id");
    // }

    const errors = requiredFields.filter((field) => !formData[field]);

    if (errors.length > 0) {
      throw new Error(
        `Les champs suivants sont obligatoires : ${errors
          .map((field) => fieldPlaceholders[field])
          .join(", ")}`
      );
    }

    if (formData.phoneNumber.length !== 10) {
      throw new Error(
        "Le numéro de téléphone doit contenir exactement 10 chiffres."
      );
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.mail)) {
      throw new Error("L'adresse mail n'est pas valide.");
    }

    if (formData.password.length < 6) {
      throw new Error("Le mot de passe doit contenir au moins 6 caractères.");
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      validateForm();

      const vehicleData = {
        ...formData,
        carType: formData.carType,
        name: formData.name,
        teamMate1Id: formData.teamMate1Id,
        teamMate2Id:
          formData.carType === "AMBULANCE" ? formData.teamMate2Id : null,
      };

      console.log("Données du véhicule à envoyer :", vehicleData);

      console.log("Vehicle :", vehicle);

      if (vehicle?.id) {
        console.log("Mise à jour du véhicule", vehicle.id, vehicleData);
        try {
          await updateVehicle(vehicleData);
          toast.success("Véhicule mis à jour avec succès !");
          onClose();
        } catch (error) {
          console.error("Erreur lors de la mise à jour du véhicule:", error);
          toast.error(`Erreur : ${error.message}`);
        }
      } else {
        try {
          await addVehicle(vehicleData);
          toast.success("Véhicule ajouté avec succès !");
          onClose();
        } catch (error) {
          console.error("Erreur lors de l'ajout du véhicule:", error);
          toast.error(`Erreur : ${error.message}`);
        }
      }
    } catch (error) {
      console.error("Erreur lors de la soumission:", error);
      toast.error(`Erreur : ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderEmployeeCombobox = (
    id,
    label,
    employees,
    disabled = false,
    filtered
  ) => {
    const employeesWithNoTeamMate = [
      {
        id: "no_teammate",
        firstName: "Pas",
        lastName: "d'équipier",
        employeeId: "Pas d'équipier",
      },
      ...employees,
    ];

    // console.log(`Employees for ${id}:`, employeesWithNoTeamMate); // Ajout de ce log

    return (
      <div>
        <Label htmlFor={id} className="text-formGrey">
          {label}
        </Label>
        <EmployeeCombobox
          employees={employeesWithNoTeamMate}
          filtered={filtered}
          style={{ color: "black" }}
          value={formData[id] || ""}
          onSelect={async (employee) => {
            console.log(`Selected employee for ${id}:`, employee); // Ajout de ce log
            handleSelectChange(id, employee ? employee.id : "UNSET");
            //TODO : Regarder si ça fonctionne ça
          }}
          disabled={disabled}
          triggerElement={
            <div
              className={`flex items-center justify-between w-full px-3 py-2 text-sm border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-ring focus:border-input ${
                disabled ? "opacity-50 cursor-not-allowed bg-gray-100" : ""
              }`}
              onClick={(e) => {
                if (disabled) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              {formData[id] === "no_teammate" ? (
                <span>Pas d&apos;équipier</span>
              ) : (
                <TeamMate
                  teamMateId={formData[id]}
                  index={id === "teamMate1Id" ? 1 : 2}
                />
              )}
              <ChevronDown className="h-4 w-4 opacity-50" />
            </div>
          }
        />
      </div>
    );
  };

  return (
    <Card className="w-[400px] bg-white shadow-lg rounded-2xl overflow-hidden">
      <CardHeader
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <Badge
            style={{
              borderRadius: 9999,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 8,
            }}
            className="bg-borderV2 text-nightBlue"
          >
            <span style={{ fontWeight: "600", fontSize: 14 }}>{displayId}</span>
          </Badge>
          <CardTitle style={{ fontSize: 24, fontWeight: "700" }}>
            {"Véhicule"}
          </CardTitle>
        </div>
        <div style={{ display: "flex", gap: 8 }}>
          <Button
            style={{
              borderRadius: 16,
              backgroundColor:
                formData.carType === "VSL" ? "#F7F9FC" : "#F6F7FF",
              padding: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 50,
              height: 50,
              border:
                formData.carType === "VSL"
                  ? "2px solid #02196C"
                  : "1px solid #DEE0F3",
            }}
            onClick={() => setCarType("VSL")}
          >
            <IconWrapper
              icon={CarIcon}
              size={18}
              className="text-nightBlue"
              strokeWidth={2.5}
            />
            <span className="text-[10px] font-bold text-yellow-500 italic">
              VSL
            </span>
          </Button>
          <Button
            style={{
              borderRadius: 16,
              backgroundColor:
                formData.carType === "AMBULANCE" ? "#F7F9FC" : "#F6F7FF",
              padding: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 50,
              height: 50,
              border:
                formData.carType === "AMBULANCE"
                  ? "2px solid #02196C"
                  : "1px solid #DEE0F3",
            }}
            onClick={() => setCarType("AMBULANCE")}
          >
            <IconWrapper
              icon={AmbulanceIcon}
              size={18}
              className="text-nightBlue"
              strokeWidth={2.5}
            />
            <span className="text-[10px] font-bold text-blue-600 italic">
              AMB
            </span>
          </Button>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <div>
          <Label htmlFor="name" className="text-formGrey">
            Plaque d&apos;immatriculation
          </Label>
          <Input
            id="name"
            placeholder="XX-XXX-XX"
            value={formData.name}
            onChange={handleInputChange}
            maxLength={9}
          />
        </div>
        <div>
          <Label htmlFor="phoneNumber" className="text-formGrey">
            Numéro de téléphone
          </Label>
          <Input
            id="phoneNumber"
            placeholder="Num Tél"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            maxLength={10}
            type="tel"
            pattern="[0-9]*"
          />
        </div>
        <div>
          <Label htmlFor="mail" className="text-formGrey">
            Email
          </Label>
          <Input
            id="mail"
            type="email"
            placeholder="Email"
            value={formData.mail}
            onChange={handleInputChange}
          />
        </div>
        <div className="">
          <Label htmlFor="password" className="text-formGrey">
            Mot de passe
          </Label>
          <div className="relative h-9 flex items-center">
            <Input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="********"
              value={formData.password}
              onChange={handleInputChange}
              // className="pr-10" // Ajout de padding à droite pour le bouton
            />
            <Button
              type="button"
              variant="ghost"
              size="sm"
              onClick={togglePasswordVisibility}
              className="absolute right-0 h-full px-3 hover:bg-transparent"
            >
              {showPassword ? (
                <EyeOffIcon className="h-4 w-4" />
              ) : (
                <EyeIcon className="h-4 w-4" />
              )}
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {renderEmployeeCombobox("teamMate1Id", "Équipier 1", employees)}
          {renderEmployeeCombobox(
            "teamMate2Id",
            "Equipier 2 - DEA",
            employeesDEA,
            formData.carType === "VSL",
            true
          )}
        </div>
      </CardContent>
      <CardFooter className="p-4 flex justify-between">
        <div className="flex gap-4">
          <Button variant="outline" onClick={onClose}>
            Fermer
          </Button>
          <Button
            className="bg-blue-600 text-white"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Chargement..." : vehicle ? "Modifier" : "Ajouter"}
          </Button>
        </div>
        {vehicle && (
          <Button
            variant="ghost"
            size="icon"
            className="bg-red-100"
            onClick={handleDelete}
          >
            <Trash2Icon className="h-6 w-6 text-red-500" />
          </Button>
        )}
      </CardFooter>
    </Card>
  );
}

export default VehiculeForm;
