import moment from "moment";
import React from "react";

import { useAuth } from "@providers/Auth";
import Palette from "@styles/Palette";

import Button from "@components/Button";

import { STATUS } from "@helpers/status";
import PlanningTransportDetailsCard from "./PlanningTransportDetailsCard";

const formatDate = (date, format) => {
  if (!date) return "-";

  // Si la date est un objet avec __time__
  if (date.__time__) {
    return moment(date.__time__).format(format);
  }

  // Si c'est un timestamp Firebase
  if (date && typeof date.toDate === "function") {
    return moment(date.toDate()).format(format);
  }

  // Si c'est une date standard
  return moment(date).format(format);
};

const isSameDay = (date1, date2) => {
  if (!date1 || !date2) return false;

  const moment1 = date1.__time__
    ? moment(date1.__time__)
    : date1.toDate
    ? moment(date1.toDate())
    : moment(date1);

  const moment2 = date2.__time__
    ? moment(date2.__time__)
    : date2.toDate
    ? moment(date2.toDate())
    : moment(date2);

  return moment1.isSame(moment2, "day");
};

function PlanningTransportList({
  Component = PlanningTransportDetailsCard,
  transports = [],
  onClick,
  containerStyle,
  cardStyle,
  selected,
  details = false,
  cardProps,
  onCancel,
  onAccept,
  onAssign,
  onRefuse,
  onCancelOrBourse,
  detectVisibility,
  actualDate,
  setActualDate,
  isBourse = false
}) {
  const userRole = useAuth().user.role;
  return (
    <div
      className={"container relative flex !p-0"}
      style={{
        ...containerStyle,
        paddingBottom: 60
      }}
    >
      {transports.length > 0 ? (
        <>
          {transports.map((d, i, a) => {
            const prevStartDate = i > 0 ? transports[i - 1].startDate : null;
            const isSameAsPrevDay = isSameDay(prevStartDate, d.startDate);
            const isLast = i === a.length - 1;
            const isSelected = selected && selected.id === d.id;
            return (
              <div key={d.id} style={{ position: "relative" }}>
                <Component
                  {...{ onClick, isBourse }}
                  details={details}
                  transport={d}
                  isReturn={d.isReturn}
                  key={i}
                  selected={[isSelected]}
                  containerStyle={{
                    ...(isSelected
                      ? {
                          borderColor: Palette.black,
                          // i want to add shadow on the bottom of the card
                          boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.2)",
                          backgroundColor:
                            userRole === "HOSPITAL"
                              ? STATUS[d.status]?.style.backgroundColor
                              : d.regulatedBy === "BOURSE" &&
                                d.bourseType === "PUBLIC"
                              ? STATUS["BOURSE"]?.style.backgroundColor
                              : d.bourseType === "PRIVATE"
                              ? Palette.purpleTranslucent
                              : STATUS[d.status]?.style.backgroundColor
                        }
                      : {
                          borderColor:
                            userRole === "HOSPITAL"
                              ? STATUS[d.status]?.style.backgroundColor
                              : d.regulatedBy === "BOURSE" &&
                                d.bourseType === "PUBLIC"
                              ? STATUS["BOURSE"]?.style.borderColor
                              : d.bourseType === "PRIVATE"
                              ? Palette.purple
                              : STATUS[d.status]?.style.borderColor,
                          backgroundColor:
                            userRole === "HOSPITAL"
                              ? STATUS[d.status]?.style.backgroundColor
                              : d.regulatedBy === "BOURSE" &&
                                d.bourseType === "PUBLIC"
                              ? STATUS["BOURSE"]?.style.backgroundColor
                              : d.bourseType === "PRIVATE"
                              ? Palette.purpleTranslucent
                              : STATUS[d.status]?.style.backgroundColor
                        }),
                    marginBottom: isLast ? 0 : 15,
                    ...cardStyle
                  }}
                  {...cardProps}
                  index={i}
                />
              </div>
            );
          })}

          {(selected?.status === "PENDING" ||
            selected?.status === "ACCEPTED") && (
            <div
              className={"container"}
              style={{
                position: "sticky",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                padding: 5
              }}
            >
              <div
                className={"containerRow"}
                style={{
                  zIndex: 1,
                  padding: 5,
                  backgroundColor: Palette.white,
                  borderRadius: 5
                }}
              >
                {selected.isAssign === false && isBourse !== true ? (
                  <Button
                    onClick={() => onAssign(selected)}
                    fullWidth
                    text="Assigner"
                    containerStyle={{ marginRight: 5 }}
                  />
                ) : selected.isAssign === true &&
                  isBourse !== true &&
                  selected.status !== "IN_PROGRESS" ? (
                  <Button
                    onClick={() => onAssign(selected)}
                    fullWidth
                    text="Réassigner"
                    containerStyle={{ marginRight: 5 }}
                  />
                ) : (
                  // only display if a element is selected
                  selected && (
                    <Button
                      onClick={() => onAccept(selected)}
                      fullWidth
                      text="Accepter"
                      variant={"primary"}
                      containerStyle={{ marginRight: 5 }}
                    />
                  )
                )}
                {selected.regulatedBy !== "BOURSE" ? null : (
                  //TODO: a remettre lors de la V3 (regles de gestion)
                  <>
                    <Button
                      onClick={() => onRefuse(selected)}
                      fullWidth
                      text="Refuser"
                      variant={"primaryError"}
                      containerStyle={{ marginRight: 5 }}
                    />
                    <Button
                      onClick={() => onCancelOrBourse(selected)}
                      fullWidth
                      text="Annuler / Mettre en bourse"
                      variant={"primaryError"}
                      containerStyle={{ marginRight: 5 }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className={"container flex"}
          style={{
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div style={{ fontWeight: 600, fontSize: 24 }}>Aucun transport</div>
        </div>
      )}
    </div>
  );
}
export default PlanningTransportList;
