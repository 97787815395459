import Button from "@components/Button";
import Input from "@components/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger
} from "@components/shadcn/ui/select";
import { SelectValue } from "@radix-ui/react-select";
import Palette from "@styles/Palette";
import React from "react";
import { useHistory } from "react-router-dom";
import CalendarWeekView from "./CalendarWeekView";

function PlanningHead({
  selected,
  setSelected,
  dataTransportStatus,
  selectedFilter,
  setSelectedFilter,
  transports,
  actualDate,
  setActualDate,
  nbrTransports,
  searchValue,
  setSearchValue,
  setTransportType
}) {
  const planningFilterBtns = [
    {
      name: `Tous\n(${nbrTransports})`,
      value: ["ALL"],
      color: "#222834"
    },
    {
      name: "A réguler",
      value: ["ACCEPTED"],
      color: "#02196C",
      imgTop: "icons/planning_filter/reguler.svg"
    },
    {
      name: "Annulé",
      value: ["CANCELED"],
      color: "#DB2C73",
      imgTop: "icons/planning_filter/annuler.svg"
    },
    {
      name: "En cours",
      value: ["IN_PROGRESS"],
      color: "#FFBBC2",
      imgTop: "icons/planning_filter/encours.svg"
    },
    {
      name: "Terminé",
      value: ["ENDED"],
      color: "#38A06C",
      imgTop: "icons/planning_filter/termine.svg"
    },
    {
      name: "Attribué",
      value: ["ASSIGNED"],
      color: "#A32FFF",
      imgTop: "icons/planning_filter/attribue.svg"
    },
    {
      name: "Bourse",
      value: ["BOURSE"],
      color: "#FEAD34",
      imgTop: "icons/planning_filter/bourse.svg"
    },
    {
      name: "Nouveau",
      value: ["PENDING"],
      color: "#4951FF",
      imgTop: "icons/planning_filter/nouveau.svg"
    }
  ];
  const planningFilterCarTypeBtns = [
    {
      name: "VSL",
      value: "VSL",
      color: "#FFB800",
      imgTop: "icons/veicule_info_modal/vslcar.svg"
    },
    {
      name: "AMB",
      value: "AMBULANCE",
      color: "#4951FF",
      imgTop: "icons/veicule_info_modal/ambulance.svg"
    }
  ];
  const history = useHistory();
  return (
    <div
      style={{
        zIndex: 1
      }}
    >
      <div className="flex flex-col" style={{ marginBottom: 30 }}>
        {/* ********************************** */}
        <div className=" flex flex-row justify-between border-b-[3px] border-rgb(222, 224, 243) p-4 bg-shadyGray2 rounded-t-3xl max-h-[170px]">
          <div>
            <CalendarWeekView
              {...{ actualDate, setActualDate }}
              containerStyle={{ marginBottom: 0 }}
            />
          </div>
          <div>
            <Button
              onClick={() => history.push("?editTransport=new")}
              fullWidth={179}
              variant="primary"
              imgLeft="icons/planning_filter/pluss.svg"
              text="Nouveau transport"
              style={{ height: "32px" }}
            />
          </div>
        </div>
        {/* **************FILTERS******************** */}
        <div className="flex flex-row container">
          <div className="w-8/12 flex flex-row m-3">
            {planningFilterBtns.map(({ name, value, color, imgTop }) => (
              <Button
                imgTop={imgTop}
                key={name}
                onClick={() => {
                  setSelected(
                    (prevSelected) =>
                      prevSelected[0]?.name === name
                        ? [{ name: "Tous", value: ["ALL"] }] // Deselect if already selected
                        : [{ name, value }] // Select new filter
                  );
                }}
                text={name}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  background: selected[0].name === name ? "#EFF1FF" : "#F6F7FF",
                  border: "solid 2px #DEE0F3",
                  borderRadius: "10px",
                  color: `${color}`,
                  marginRight: "8px"
                }}
              />
            ))}
          </div>
          <div className="w-1/12 flex flex-row m-3">
            {planningFilterCarTypeBtns.map(({ name, value, color, imgTop }) => (
              <Button
                imgTop={imgTop}
                iconStyle={{ width: "50px" }}
                key={name}
                onClick={() =>
                  setSelectedFilter((prev) => ({
                    ...prev,
                    value: value,
                    isActive: !prev?.isActive
                  }))
                }
                text={name}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  background:
                    selectedFilter?.isActive && selectedFilter?.value === value
                      ? "#EFF1FF"
                      : "#F6F7FF",
                  border: "solid 2px #DEE0F3",
                  borderRadius: "10px",
                  color: `${color}`,
                  marginRight: "8px"
                }}
              />
            ))}
          </div>
          <div className="w-2/12 m-3">
            <Select onValueChange={(value) => setTransportType(value)}>
              <SelectTrigger className="w-[180px] h-10 ml-3 text-[#A1A9B8] text-[14px] font-bold border-2">
                <SelectValue placeholder="Type" />
              </SelectTrigger>
              <SelectContent className="text-[#A1A9B8] text-[14px] font-bold">
                <SelectItem value="Tous">Tous</SelectItem>
                <SelectItem value="Aller simple">Aller simple</SelectItem>
                <SelectItem value="Retour simple">Retour simple</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div id="zak" className="w-2/12">
            <div className="my-3 mr-3">
              <Input
                inputStyle={{
                  backgroundColor: "transparent",
                  color: Palette.nightBlue60,
                  borderLeft: "solid 2px #E5E6E8",
                  paddingLeft: "8px"
                }}
                inputContainerStyle={{
                  backgroundColor: "transparent",
                  borderRadius: 6,
                  width: "235px"
                }}
                placeholder="Rechercher"
                imgLeft={"/icons/planning_filter/search.svg"}
                iconLeftStyle={{ width: "20px" }}
                className="placeHolderStyle"
                style={{
                  border: "solid 2px #E5E6E8",
                  color: "#A1A9B8",
                  backgroundColor: "transparent"
                }}
                onChange={setSearchValue}
                value={searchValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanningHead;
