export const dataDropdown = {
  dataTransportStatus: [
    { name: "Toutes les courses", value: ["ALL"] },
    { name: "Courses à venir", value: ["ACCEPTED"] },
    { name: "Courses terminées", value: ["ENDED", "REFUSED"] },
    {
      name: "Courses annulées",
      value: ["CANCELED"]
    },
    { name: "Courses en attente", value: ["PENDING"] }
  ],
  dataNotificationStatus: [
    { name: "Toutes les notifications", value: ["ALL"], type: ["ALL"] },
    { name: "Refusé", value: ["REFUSED"], type: ["TRANSPORT_REFUSED"] },
    { name: "Terminé", value: ["ENDED"], type: ["TRANSPORT_ENDED"] },
    {
      name: "Nouveau",
      value: ["PENDING"],
      type: ["NEW_TRANSPORT"]
    },
    { name: "Bourse", value: ["BOURSE"], type: ["TRANSPORT_BOURSE"] },
    {
      name: "Annulé",
      value: ["CANCELED"],
      type: ["TRANSPORT_CANCELED"]
    },
    { name: "A réguler", value: ["ACCEPTED"], type: ["TRANSPORT_ACCEPTED"] },
    {
      name: "Attribué",
      value: ["ASSIGNED"],
      type: ["TRANSPORT_ATTRIBUTED_PENDING", "TRANSPORT_ATTRIBUTED_ACCEPTED"]
    },
    {
      name: "En cours",
      value: ["IN_PROGRESS"],
      type: [
        "TRANSPORT_IN_PROGRESS_TO_PATIENT",
        "TRANSPORT_IN_PROGRESS_TO_PLACE"
      ]
    }
  ],
  dataNotificationStatusHospital: [
    { name: "Toutes les notifications", value: ["ALL"], type: ["ALL"] },
    { name: "Refusé", value: ["REFUSED"], type: ["TRANSPORT_REFUSED"] },
    { name: "Terminé", value: ["ENDED"], type: ["TRANSPORT_ENDED"] },
    {
      name: "Nouveau",
      value: ["PENDING"],
      type: ["NEW_TRANSPORT"]
    },
    {
      name: "Accepté",
      value: ["ACCEPTED"],
      type: ["TRANSPORT_ACCEPTED_BY_REGULATOR"]
    },
    { name: "Bourse", value: ["BOURSE"], type: ["TRANSPORT_BOURSE"] },
    {
      name: "Annulé",
      value: ["CANCELED"],
      type: ["TRANSPORT_CANCELED"]
    },
    {
      name: "En cours",
      value: ["IN_PROGRESS"],
      type: [
        "TRANSPORT_IN_PROGRESS_TO_PATIENT",
        "TRANSPORT_IN_PROGRESS_TO_PLACE"
      ]
    }
  ],
  transportStatusData: [
    { name: "Status du transport", value: ["title"], type: [""] },
    { name: "Tous", value: ["ALL"], type: ["ALL"] },
    { name: "Terminé", value: ["ENDED"], type: ["TRANSPORT_ENDED"] },
    {
      name: "Nouveau",
      value: ["PENDING"],
      type: ["NEW_TRANSPORT"]
    },
    { name: "Bourse", value: ["BOURSE"], type: ["TRANSPORT_BOURSE"] },
    {
      name: "Annulé",
      value: ["CANCELED"],
      type: ["TRANSPORT_CANCELED"]
    },
    { name: "A réguler", value: ["ACCEPTED"], type: ["TRANSPORT_ACCEPTED"] },
    {
      name: "Attribué",
      value: ["ASSIGNED"],
      type: ["TRANSPORT_ATTRIBUTED_PENDING", "TRANSPORT_ATTRIBUTED_ACCEPTED"]
    },
    {
      name: "En cours",
      value: ["IN_PROGRESS"],
      type: [
        "TRANSPORT_IN_PROGRESS_TO_PATIENT",
        "TRANSPORT_IN_PROGRESS_TO_PLACE"
      ]
    }
  ],
  transportStatusHospitalData: [
    { name: "Status du transport", value: ["title"], type: [""] },
    { name: "Tous", value: ["ALL"], type: ["ALL"] },
    { name: "Refusé", value: ["REFUSED"], type: ["TRANSPORT_REFUSED"] },
    { name: "Terminé", value: ["ENDED"], type: ["TRANSPORT_ENDED"] },
    {
      name: "Nouveau",
      value: ["PENDING"],
      type: ["NEW_TRANSPORT"]
    },
    {
      name: "Accepté",
      value: ["ACCEPTED"],
      type: ["TRANSPORT_ACCEPTED_BY_REGULATOR"]
    },
    { name: "Bourse", value: ["BOURSE"], type: ["TRANSPORT_BOURSE"] },
    {
      name: "Annulé",
      value: ["CANCELED"],
      type: ["TRANSPORT_CANCELED"]
    },
    {
      name: "En cours",
      value: ["IN_PROGRESS"],
      type: [
        "TRANSPORT_IN_PROGRESS_TO_PATIENT",
        "TRANSPORT_IN_PROGRESS_TO_PLACE"
      ]
    }
  ],
  planningTransportStatusData: [
    { name: "Tous", value: ["ALL"], type: ["ALL"] },
    { name: "Terminé", value: ["ENDED"], type: ["TRANSPORT_ENDED"] },
    {
      name: "Nouveau",
      value: ["PENDING"],
      type: ["NEW_TRANSPORT"]
    },
    { name: "Bourse", value: ["BOURSE"], type: ["TRANSPORT_BOURSE"] },
    {
      name: "Annulé",
      value: ["CANCELED"],
      type: ["TRANSPORT_CANCELED"]
    },
    { name: "A réguler", value: ["ACCEPTED"], type: ["TRANSPORT_ACCEPTED"] },
    {
      name: "Attribué",
      value: ["ASSIGNED"],
      type: ["TRANSPORT_ATTRIBUTED_PENDING", "TRANSPORT_ATTRIBUTED_ACCEPTED"]
    },
    {
      name: "En cours",
      value: ["IN_PROGRESS"],
      type: [
        "TRANSPORT_IN_PROGRESS_TO_PATIENT",
        "TRANSPORT_IN_PROGRESS_TO_PLACE"
      ]
    }
  ],
  planningTransportStatusHospitalData: [
    { name: "Tous", value: ["ALL"], type: ["ALL"] },
    { name: "Refusé", value: ["REFUSED"], type: ["TRANSPORT_REFUSED"] },
    { name: "Terminé", value: ["ENDED"], type: ["TRANSPORT_ENDED"] },
    {
      name: "Nouveau",
      value: ["PENDING"],
      type: ["NEW_TRANSPORT"]
    },
    {
      name: "Accepté",
      value: ["ACCEPTED"],
      type: ["TRANSPORT_ACCEPTED_BY_REGULATOR"]
    },
    { name: "Bourse", value: ["BOURSE"], type: ["TRANSPORT_BOURSE"] },
    {
      name: "Annulé",
      value: ["CANCELED"],
      type: ["TRANSPORT_CANCELED"]
    },
    {
      name: "En cours",
      value: ["IN_PROGRESS"],
      type: [
        "TRANSPORT_IN_PROGRESS_TO_PATIENT",
        "TRANSPORT_IN_PROGRESS_TO_PLACE"
      ]
    }
  ]
};
