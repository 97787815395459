import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import TransportResume from "../../../components/TransportResume";
import Palette from "../../../styles/Palette";
import moment from "moment";
import { toast } from "react-hot-toast";
import Colors from "../../../styles/Colors";
import { useGlobal } from "reactn";
import {
  acceptTransport,
  assignTransport,
  createNotification,
} from "../../../services/Firebase";
import firebase from "firebase/compat/app";
import { useAuth } from "../../../providers/Auth";
import { notificationTypes } from "../../../helpers/notifications";

const formatDateToJS = (date) => {
  if (!date) return new Date();

  // Si la date est un objet avec __time__
  if (date.__time__) {
    return new Date(date.__time__);
  }

  // Si c'est un timestamp Firebase
  if (date && typeof date.toDate === "function") {
    return date.toDate();
  }

  // Si c'est déjà une date JS
  if (date instanceof Date) {
    return date;
  }

  // Si c'est une date standard
  return new Date(date);
};

const formatDate = (date) => {
  if (!date) return "-";

  try {
    // Si c'est déjà un moment
    if (moment.isMoment(date)) {
      return date.format("HH:mm");
    }

    // Si la date est un objet avec __time__
    if (date.__time__) {
      return moment(date.__time__).format("HH:mm");
    }

    // Si c'est un timestamp Firebase
    if (date && typeof date.toDate === "function") {
      return moment(date.toDate()).format("HH:mm");
    }

    // Si c'est une date JS
    if (date instanceof Date) {
      return moment(date).format("HH:mm");
    }

    // Si c'est une date standard
    return moment(date).format("HH:mm");
  } catch (error) {
    console.error("Error formatting date:", error);
    return "-";
  }
};

export default () => {
  const { user } = useAuth();
  const [transport, setTransport] = useGlobal("transportToAssignAndChangePEC");
  const [, setTransportToAssign] = useGlobal("transportToAssign");
  const isVisible = !!transport;

  const now = moment();
  const currentHour = now.hour();
  const currentMinute = now.minute();

  const [demDate, setDemDate] = useState(() => formatDateToJS(new Date()));
  const [startDate, setStartDate] = useState(() => formatDateToJS(new Date()));
  const [viewState, setViewState] = useState("EDIT"); // EDIT / CONFIRM
  const [assignType, setAssignType] = useState("NOW"); // NOW / LATER

  useEffect(() => {
    if (isVisible && transport) {
      const formattedStartDate = formatDateToJS(transport.startDate);
      const formattedDemDate = formatDateToJS(
        transport.demDate || transport.startDate
      );

      setStartDate(formattedStartDate);
      setDemDate(formattedDemDate);
    } else {
      setDemDate(formatDateToJS(new Date()));
      setStartDate(formatDateToJS(new Date()));
      setViewState("EDIT");
      setAssignType("NOW");
    }
  }, [isVisible, transport]);

  const onPressEdit = async (assignType) => {
    try {
      setAssignType(assignType);

      // Vérification des dates
      const startMoment = moment(startDate);
      const endMoment = moment(transport.endDate);
      const demMoment = moment(demDate);

      if (startMoment.isSameOrAfter(endMoment)) {
        throw new Error(
          "L'heure de prise en charge doit être antérieure à l'heure de rendez-vous"
        );
      }

      if (demMoment.isAfter(startMoment)) {
        throw new Error(
          "L'heure de démarrage doit être antérieure ou égale à l'heure de prise en charge"
        );
      }

      // Si on clique sur "Maintenant"
      if (assignType === "NOW") {
        const currentTime = moment();

        // Si c'est un transport retour
        if (transport.goToTransportId) {
          await setTransportToAssign({
            ...transport,
            demDate: currentTime.toDate(),
            startDate: currentTime.toDate(),
            regulatedBy: user.id,
            bourseType: firebase.firestore.FieldValue.delete(),
            bourseGroup: firebase.firestore.FieldValue.delete(),
          });
        } else {
          // Transport aller
          await setTransportToAssign({
            ...transport,
            demDate: demDate,
            startDate: startDate,
            regulatedBy: user.id,
            bourseType: firebase.firestore.FieldValue.delete(),
            bourseGroup: firebase.firestore.FieldValue.delete(),
          });
        }

        // Gestion du transport retour si existant
        if (transport.returnTransportId) {
          await acceptTransport(
            transport.returnTransportId,
            { demDate: demDate, startDate: startDate },
            transport
          );
        }

        await setTransport(null);
      }
      // Si on clique sur "Plus tard"
      else if (assignType === "LATER") {
        await acceptTransport(transport.id, { demDate, startDate }, transport);

        if (transport.returnTransportId) {
          await acceptTransport(
            transport.returnTransportId,
            { demDate, startDate },
            transport
          );
          toast.success("Transport A/R accepté.");
        } else {
          toast.success("Transport accepté.");
        }

        await setTransport(null);
      }
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  const onPressConfirm = async () => {
    try {
      if (assignType === "NOW") {
        await setTransportToAssign({
          ...transport,
          startDate,
          demDate,
          regulatedBy: user.id,
          bourseType: firebase.firestore.FieldValue.delete(),
          bourseGroup: firebase.firestore.FieldValue.delete(),
        });
        await setTransport(null);
      } else {
        if (transport.returnTransportId) {
          await acceptTransport(
            transport.id,
            {
              startDate,
              demDate,
            },
            transport
          );
          //TODO: NOTIF HERE RETURN ID
          await acceptTransport(
            transport.returnTransportId,
            {
              startDate,
              demDate,
            },
            transport
          );
          toast.success("Transport A/R accepté et modifié.");
          await setTransport(null);
        }
        await acceptTransport(
          transport.id,
          {
            startDate,
            demDate,
          },
          transport
        );
        toast.success("Transport accepté et modifié.");
        await setTransport(null);
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };

  if (!isVisible) return null;

  if (viewState === "EDIT")
    return (
      <Modal
        showPrevButton={false}
        onClose={() => setTransport(null)}
        style={{ width: 500 }}
        onTitle={() => (
          <div
            className={"containerCenter"}
            style={{
              fontSize: 20,
              fontWeight: 500,
            }}
          >
            <div style={{ marginRight: 10 }}>Voulez-vous attribuer le</div>
            <div style={{ color: Palette.red }}>
              Transport {transport.id?.slice(0, 5)}
            </div>
          </div>
        )}
        onFooter={() => (
          <div
            className={"containerCenter"}
            style={{ width: 166, justifyContent: "center" }}
          >
            <Button
              text={"Maintenant"}
              containerStyle={{ marginBottom: 10 }}
              fullWidth
              onClick={() => onPressEdit("NOW")}
            />
            <Button
              text={"Plus tard"}
              variant={"primaryInfo"}
              containerStyle={{ marginBottom: 10 }}
              fullWidth
              onClick={() => onPressEdit("LATER")}
            />
            <Button
              text={"Retour"}
              variant={"secondary"}
              fullWidth
              onClick={() => setTransport(null)}
            />
          </div>
        )}
      >
        <div
          className={"container"}
          style={{ marginBottom: 20, flex: 1, alignItems: "center" }}
        >
          <TransportResume
            editable
            transport={transport}
            onChange={setStartDate}
            onChangeDem={setDemDate}
            variant={"MODAL"}
            containerStyle={{ width: 400 }}
          />
        </div>
      </Modal>
    );

  if (viewState === "CONFIRM")
    return (
      <Modal
        style={{ width: 334 }}
        showPrevButton={false}
        onClose={() => setViewState("EDIT")}
        onTitle={() => (
          <div
            className={"containerCenter"}
            style={{
              fontSize: 15,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Etes-vous sûr de vouloir changer l&apos;horaire de PEC ?
          </div>
        )}
        onFooter={() => (
          <div
            className={"containerCenter"}
            style={{ width: 166, justifyContent: "center" }}
          >
            <Button
              text={"Modifier"}
              containerStyle={{ height: 40, marginBottom: 10 }}
              fullWidth
              onClick={onPressConfirm}
            />

            <Button
              onClick={() => setViewState("EDIT")}
              text={"Annuler"}
              variant={"secondary"}
              containerStyle={{ height: 40 }}
              fullWidth
            />
          </div>
        )}
      >
        <div
          className={"containerRowCenter"}
          style={{ marginBottom: 20, flex: 1, alignItems: "center" }}
        >
          <div className={"containerCenter"}>
            <div style={{ fontSize: 12, color: Colors.text.secondary }}>
              AVANT
            </div>
            <div style={{ fontSize: 15, fontWeight: 500, color: Palette.red }}>
              {formatDate(transport?.startDate)}
            </div>
          </div>
          <img
            src={"/icons/arrow.png"}
            style={{
              marginLeft: 10,
              marginRight: 10,
              width: 12,
              height: 14,
              transform: "rotate(90deg)",
            }}
          />
          <div className={"containerCenter"}>
            <div style={{ fontSize: 12, color: Colors.text.secondary }}>
              APRES
            </div>
            <div style={{ fontSize: 15, fontWeight: 500, color: Palette.red }}>
              {formatDate(startDate)}
            </div>
          </div>
        </div>
      </Modal>
    );
};
