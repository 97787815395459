import { REASONS2 } from "@data/transports";
import { useZustandStore } from "@helpers/store";
import useDataFromRef from "@hooks/useDataFromRef";
import { useAuth } from "@providers/Auth";
import { useRegulator } from "@providers/RegulatorProvider";
import { formatTransportDoc, Transports } from "@services/Firebase";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import PlanningTransportState from "./PlanningTransportState";
import PlanningTransportType from "./PlanningTransportType";
import TexteScroller from "./TexteScroller";

export default function PlanningTransportDetailsCard({
  transport: {
    id,
    startDate,
    endDate,
    returnTransportId = undefined,
    from,
    to,
    carType,
    goToTransportId,
    status,
    patient,
    demDate = undefined,
    driver = undefined,
    reason = undefined
  },
  transport,
  onClick,
  containerStyle,
  details = false,
  isReturn,
  index
}) {
  const setPlanningTransportDetail  = useZustandStore(
    (state) => state.setPlanningTransportDetail
  );
  const history = useHistory();
  const uid = useAuth().user.uid;
  const terminatedStatus = ["ENDED", "REFUSED", "CANCELED"];

  const { data: allerTransport } = useDataFromRef({
    ref: Transports.doc(goToTransportId),
    refreshArray: [goToTransportId],
    format: formatTransportDoc,
    condition: !!goToTransportId,
    simpleRef: true,
    listener: true
  });

  if (goToTransportId && !terminatedStatus.includes(allerTransport?.status)) {
    return null;
  }
  const transportReason = (reason) => {
    const foundReason = REASONS2.find(({ value }) => value === reason);
    return foundReason ? foundReason.name : null; // Return name or null if not found
  };
  const { employees } = useRegulator();
  const teamMate1 = employees.find((emp) => emp.id === driver?.teamMate1Id);
  const teamMate2 = employees.find((emp) => emp.id === driver?.teamMate2Id);
  return (
    <div
      className={`flex flex-row text-[#02196C] font-bold uppercase max-h-[40px] cursor-pointer   hover:bg-[#E3EEFF] justify-between p-2 ${
        index % 2 == !0 ? "bg-shadyGray2 border-y-2 border-shadyGray3" : ""
      }`}
      onClick={() => setPlanningTransportDetail(transport)}
    >
      <div className=" planning-col-5">
        <PlanningTransportState status={status} />
      </div>
      <div className="planning-col-5 ">
        <PlanningTransportType returnTransportId={returnTransportId} />
      </div>
      <div className="planning-col-15 ">
        {patient.firstName} {patient.lastName}
      </div>
      <div className="planning-col-5 ">{moment(startDate).format("hh:mm")}</div>
      <div className="planning-col-5 ">
        {driver ? moment(demDate).format("hh:mm") : ""}
      </div>
      <div className="planning-col-15 ">
        <TexteScroller>{from.address}</TexteScroller>
      </div>
      <div className="planning-col-5 ">{moment(endDate).format("hh:mm")}</div>
      <div className="planning-col-15">
        <TexteScroller>{to.address}</TexteScroller>
      </div>
      <div className="planning-col-10  flex flex-row">
        {carType === "VSL" ? (
          <img width={25} src="icons/veicule_info_modal/vslcar.svg" />
        ) : (
          <img width={23} src="icons/veicule_info_modal/ambulance.svg" />
        )}
        <p className="pl-3">{driver?.name}</p>
      </div>
      <div className="planning-col-5 ">{teamMate1?.employeeId}</div>
      <div className="planning-col-5 ">{teamMate2?.employeeId}</div>
      <div className="planning-col-10  ">{transportReason(reason)}</div>
    </div>
  );
}
