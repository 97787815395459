import React from "react";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import TransportList from "../../components/TransportList";
import useDataFromRef from "../../hooks/useDataFromRef";
import Global from "../../styles/Global";
import Colors from "../../styles/Colors";
import moment from "moment";
import Palette from "../../styles/Palette";
import Button from "../../components/Button";
import { toast } from "react-hot-toast";
import TransportDetailsCard from "../../components/TransportDetailsCard";
import {
  formatPatientDoc,
  formatTransportDoc,
  Patients,
  Patients as RefPatients,
  Transports as RefTransports,
} from "../../services/Firebase";

export default function Patient({ location, history }) {
  const params = new URLSearchParams(location.search);
  const patientId = params.get("patient");

  const { data: patient, loading: patientLoading } = useDataFromRef({
    ref: patientId && RefPatients.doc(patientId),
    format: formatPatientDoc,
    initialState: null,
    listener: true,
    condition: patientId,
    refreshArray: [patientId],
    simpleRef: true,
  });

  const { data: transports, loading: transportsLoading } = useDataFromRef({
    ref:
      patientId &&
      RefTransports.where("patient.id", "==", patientId).orderBy(
        "startDate",
        "asc"
      ),
    format: formatTransportDoc,
    listener: true,
    condition: patientId,
    refreshArray: [patientId],
  });

  const { data: futureTransports } = useDataFromRef({
    ref:
      patientId &&
      RefTransports.where("patient.id", "==", patientId)
        .where("startDate", ">=", moment().toDate())
        .orderBy("employeeId", "asc"),
    format: formatTransportDoc,
    listener: false,
    condition: patientId,
    refreshArray: [patientId],
  });

  const loading = patientLoading || transportsLoading;

  function Empty() {
    return (
      <div
        style={{ height: "100%", width: "100%" }}
        className={"containerCenter"}
      >
        <h1>Patient introuvable</h1>
      </div>
    );
  }

  function Patient() {
    // eslint-disable-next-line no-unused-vars
    const { address, birthDate, socialSecurityNumber, phoneNumber } = patient;
    return (
      <div className={"containerRow"} style={{ marginBottom: 30 }}>
        <div className={"flex"} style={{ ...Global.box, marginRight: 30 }}>
          <div style={{ fontWeight: 500, fontSize: 17, marginBottom: 15 }}>
            Adresse
          </div>
          <div style={{ fontSize: 15 }}>
            <div>{address?.address}</div>
            <div>
              {address?.postalCode} {address?.city}
            </div>
            {address?.complement && (
              <div className={"containerRow"}>
                <div style={{ color: Colors.text.secondary }}>
                  Complément:&nbsp;
                </div>
                <div>{address?.complement}</div>
              </div>
            )}
          </div>
        </div>
        <div className={"flex"} style={{ ...Global.box, marginRight: 30 }}>
          <div style={{ fontWeight: 500, fontSize: 17, marginBottom: 15 }}>
            Informations
          </div>
          <div style={{ fontSize: 15 }}>
            <div className={"containerRow"}>
              <div style={{ color: Colors.text.secondary }}>
                Date de naissance:&nbsp;
              </div>
              <div>{moment(birthDate).format("l")}</div>
            </div>
            <div className={"containerRow"}>
              <div style={{ color: Colors.text.secondary }}>N°SS:&nbsp;</div>
              <div>{socialSecurityNumber}</div>
            </div>
            <div className={"containerRow"}>
              <div style={{ color: Colors.text.secondary }}>
                Téléphone:&nbsp;
              </div>
              <div>{phoneNumber}</div>
            </div>
          </div>
        </div>
        <div style={{ ...Global.box, flex: 0.66 }}>
          <div style={{ fontWeight: 500, fontSize: 17 }}>À venir</div>
          <div className={"containerCenter"}>
            <div style={{ fontWeight: 700, fontSize: 48, color: Palette.red }}>
              {futureTransports?.length}
            </div>
            <div style={{ fontSize: 15 }}>transports</div>
          </div>
        </div>
      </div>
    );
  }

  function Transports() {
    return (
      <div>
        <div style={{ fontSize: 20, fontWeight: 600, marginBottom: 20 }}>
          Historique des prises en charge
        </div>
        <TransportList {...{ transports }} Component={TransportDetailsCard} />
      </div>
    );
  }

  const Actions = () => (
    <div
      className={"containerRow"}
      style={{
        ...Global.containerItem,
        padding: 10,
        marginLeft: 10,
        position: "absolute",
        right: 0,
        marginRight: 20,
      }}
    >
      <Button
        tooltip={"Modifier"}
        onClick={() => history.push({ search: `?editPatient=${patientId}` })}
        variant="secondary"
        size="icon"
        imgLeft={"/icons/edit.png"}
        iconStyle={{
          width: 20,
          height: 20,
        }}
        containerStyle={{
          marginRight: 10,
        }}
      />
      <Button
        tooltip={"Supprimer"}
        onClick={async () => {
          if (
            window.confirm("Êtes-vous sûr de vouloir supprimer ce patient ?")
          ) {
            history.goBack();
            await Patients.doc(patientId).delete();
            toast.success("Patient supprimé");
          }
        }}
        variant="secondaryError"
        size="icon"
        imgLeft={"/icons/delete.png"}
        iconStyle={{
          width: 22,
          height: 22,
        }}
      />
    </div>
  );

  return (
    patientId && (
      <Modal
        style={{ maxHeight: "60vh" }}
        onTitle={() => (
          <div
            className={"container"}
            style={{ height: 45, justifyContent: "center" }}
          >
            <div
              className={"containerRow"}
              style={{ fontSize: 20, alignItems: "center" }}
            >
              <div style={{ fontWeight: 500, marginRight: 10 }}>Patient</div>
              <div style={{ fontWeight: 600, color: Palette.red }}>
                {patient?.gender === "male" ? "M" : "Mme"} {patient?.firstName}{" "}
                {patient?.lastName}
              </div>
              <Actions />
            </div>
          </div>
        )}
      >
        {loading ? (
          <Loading />
        ) : patient ? (
          <>
            <Patient containerStyle={{ marginBottom: 30 }} />
            <Transports />
            {/* <Actions /> */}
          </>
        ) : (
          <Empty />
        )}
      </Modal>
    )
  );
}
