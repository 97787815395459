import React from "react";
import PlanningTransportList from "./PlanningTransportList";

function PlanningBody({
  transports,
  actualDate,
  setActualDate,
  TransporDetailsCard
}) {
  return (
    <>
      <div className="flex flex-row justify-start gray50 bg-shadyGray4 p-2 max-h-[40px]">
        <div className="planning-col-5">E.</div>
        <div className="planning-col-5">T.</div>
        <div className="planning-col-15">PATIENT</div>
        <div className="planning-col-5">DEP</div>
        <div className="planning-col-5">PEC</div>
        <div className="planning-col-15">LPC</div>
        <div className="planning-col-5">RDV</div>
        <div className="planning-col-15">LPC</div>
        <div className="planning-col-10">VEHICULE</div>
        <div className="planning-col-5">CH.</div>
        <div className="planning-col-5">EQ.</div>
        <div className="planning-col-10">RAISON</div>
      </div>

      <PlanningTransportList
        {...{ transports, actualDate, setActualDate }}
        Component={TransporDetailsCard}
        details
      />
    </>
  );
}

export default PlanningBody;
