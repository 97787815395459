import React, { useState, useEffect } from "react";
import { User } from "lucide-react";
import LayoutMain from "../../layouts/main";
import { FaCarSide } from "react-icons/fa";
import { VehicleDataTable } from "../../components/Table/vehicle-data-table";
import { EmployeeDataTable } from "../../components/Table/employee-data-table";
import AmbulancierForm from "../../components/Card/AmbulancierForm";
import VehiculeForm from "../../components/Card/VehiculeForm";
import { useRegulator } from "@providers/RegulatorProvider";
import Loading from "../../components/Loading";

export default function VehiclesAndEmployees() {
  const { employees, vehicles, loading: regulatorLoading } = useRegulator();

  const [loading, setLoading] = useState(true);
  const [totalEntities, setTotalEntities] = useState(0);
  const [generalActiveTab, setGeneralActiveTab] = useState("Véhicules");
  const [showForm, setShowForm] = useState({
    ambulancier: false,
    vehicule: false,
  });
  const [selected, setSelected] = useState({ employee: null, vehicle: null });

  useEffect(() => {
    if (!regulatorLoading) {
      setLoading(false);
      setTotalEntities(vehicles.length + employees.length);
    }
  }, [regulatorLoading, vehicles, employees]);

  const handleFormAction = (type, action, item = null) => {
    setShowForm((prev) => ({ ...prev, [type]: action === "open" }));
    setSelected((prev) => ({
      ...prev,
      [type === "ambulancier" ? "employee" : "vehicle"]:
        action === "open" ? item : null,
    }));
  };

  return (
    <LayoutMain>
      <div className="container flex flex-col ">
        <div className="bg-slate-100 rounded-t-3xl flex mb-6 justify-between p-6 max-h-20">
          <h1 className="text-2xl font-bold">
            Véhicules & Employés{" "}
            <span className="ml-1 px-2 py-1 bg-blue-100 text-blue-900 rounded-full text-sm font-normal">
              {totalEntities}
            </span>
          </h1>
          <div className="space-x-2">
            <div className="mb-4 flex space-x-2 bg-gray-100 p-1 mr-7 rounded-lg">
              {[
                { tab: "Véhicules", icon: FaCarSide },
                { tab: "Employés", icon: User },
              ].map(({ tab, icon: Icon }) => (
                <button
                  key={tab}
                  className={`p-2 rounded-md transition-colors duration-200 ${
                    generalActiveTab === tab
                      ? "bg-white text-blue-600 shadow-sm"
                      : "text-gray-600 hover:bg-gray-200"
                  }`}
                  onClick={() => setGeneralActiveTab(tab)}
                >
                  <Icon size={20} />
                </button>
              ))}
            </div>
          </div>
        </div>

        <div
          className="flex-grow flex flex-col p-12 overflow-hidden"
          style={{ marginLeft: -20 }}
        >
          {generalActiveTab === "Véhicules" ? (
            <VehicleDataTable
              data={vehicles}
              onEditVehicle={(vehicle) =>
                handleFormAction("vehicule", "open", vehicle)
              }
            />
          ) : (
            <EmployeeDataTable
              data={employees}
              onEditEmployee={(employee) =>
                handleFormAction("ambulancier", "open", employee)
              }
            />
          )}
        </div>
      </div>

      {showForm.ambulancier && (
        <AmbulancierForm
          onClose={() => handleFormAction("ambulancier", "close")}
          employee={selected.employee}
        />
      )}

      {showForm.vehicule && (
        <VehiculeForm
          onClose={() => handleFormAction("vehicule", "close")}
          vehicle={selected.vehicle}
        />
      )}
    </LayoutMain>
  );
}
