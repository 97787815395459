import React, { createContext, useContext, useState, useEffect } from "react";
import { Employees, Users } from "../services/Firebase";
import { useAuth } from "./Auth";
import useDataFromRef from "../hooks/useDataFromRef";
import firebase from "firebase/compat/app";
import "firebase/compat/functions"; // Importer les fonctions Firebase

const RegulatorContext = createContext();

export function useRegulator() {
  return useContext(RegulatorContext);
}

const checkEmployeeAvailability = async (employeeId) => {
  if (!employeeId) return true; // Si pas d'ambulancier sélectionné, il est disponible

  const snapshots = await Promise.all([
    Users.where("teamMate1Id", "==", employeeId).get(),
    Users.where("teamMate2Id", "==", employeeId).get(),
  ]);

  const isAvailable = snapshots.every((snapshot) => snapshot.empty);
  if (!isAvailable) {
    throw new Error("L'employé est déjà assigné à un véhicule");
  }
  return isAvailable;
};

export function RegulatorProvider({ children }) {
  const { user } = useAuth();
  const [employeeCounter, setEmployeeCounter] = useState(0);
  const [deletedEmployeeIds, setDeletedEmployeeIds] = useState([]);
  const [vehicleCounter, setVehicleCounter] = useState(0);
  const [deletedVehicleIds, setDeletedVehicleIds] = useState([]);

  // Utilisation de useDataFromRef pour récupérer les employés
  const { data: employees, loading: loadingEmployees } = useDataFromRef({
    ref:
      user?.id &&
      user.role === "REGULATOR" &&
      Employees.where("createdBy", "==", user.id).orderBy("employeeId"),
    initialState: [],
    listener: true,
    refreshArray: [user?.id],
    condition: !!user?.id && user.role === "REGULATOR",
  });

  // Utilisation de useDataFromRef pour récupérer les véhicules
  const { data: vehicles, loading: loadingVehicles } = useDataFromRef({
    ref:
      user?.id &&
      user.role === "REGULATOR" &&
      Users.where("regulatedBy", "==", user.id).where("role", "==", "DRIVER"),
    initialState: [],
    listener: true,
    refreshArray: [user?.id],
    condition: !!user?.id && user.role === "REGULATOR",
  });

  useEffect(() => {
    if (employees.length > 0) {
      const ids = employees.map((emp) => emp.employeeId);
      const maxId = Math.max(...ids);
      const allIds = Array.from({ length: maxId }, (_, i) => i + 1);
      const deletedIds = allIds.filter((id) => !ids.includes(id));

      setEmployeeCounter(maxId);
      setDeletedEmployeeIds(deletedIds);
    }
    if (vehicles.length > 0) {
      const ids = vehicles.map((veh) => veh.vehicleId);
      const maxId = Math.max(...ids);
      const allIds = Array.from({ length: maxId }, (_, i) => i + 1);
      const deletedIds = allIds.filter((id) => !ids.includes(id));

      setVehicleCounter(maxId);
      setDeletedVehicleIds(deletedIds);
    }
  }, [employees, vehicles]);

  // Nouvelle fonction pour lire le prochain ID sans l'incrémenter
  const peekNextEmployeeId = () => {
    if (deletedEmployeeIds.length > 0) {
      // Retourne le plus petit ID supprimé disponible
      return Math.min(...deletedEmployeeIds);
    }
    // Sinon retourne le prochain ID séquentiel
    return employeeCounter + 1;
  };

  const getNextEmployeeId = async () => {
    let nextEmployeeId;
    const isIdUsed = async (id) => {
      const existingEmployees = await Employees.where("employeeId", "==", id)
        .where("createdBy", "==", user.id)
        .get();
      return !existingEmployees.empty;
    };

    if (deletedEmployeeIds.length > 0) {
      for (const id of deletedEmployeeIds) {
        if (!(await isIdUsed(id))) {
          nextEmployeeId = id;
          setDeletedEmployeeIds((prev) =>
            prev.filter((deletedId) => deletedId !== id)
          );
          break;
        }
      }
    }

    if (!nextEmployeeId) {
      let currentCounter = employeeCounter;
      do {
        currentCounter++;
      } while (await isIdUsed(currentCounter));
      nextEmployeeId = currentCounter;
      setEmployeeCounter(currentCounter);
    }

    return nextEmployeeId;
  };

  const getNextVehicleId = async () => {
    let nextVehicleId;
    const isIdUsed = async (id) => {
      const existingVehicles = await Users.where("vehicleId", "==", id).get();
      return !existingVehicles.empty;
    };

    if (deletedVehicleIds.length > 0) {
      for (const id of deletedVehicleIds) {
        if (!(await isIdUsed(id))) {
          nextVehicleId = id;
          setDeletedVehicleIds((prev) =>
            prev.filter((deletedId) => deletedId !== id)
          );
          break;
        }
      }
    }

    if (!nextVehicleId) {
      let currentCounter = vehicleCounter;
      do {
        currentCounter++;
      } while (await isIdUsed(currentCounter));
      nextVehicleId = currentCounter;
      setVehicleCounter(currentCounter);
    }

    return nextVehicleId;
  };

  const addEmployee = async (employeeData) => {
    try {
      const nextEmployeeId = await getNextEmployeeId();
      const docRef = await Employees.add({
        ...employeeData,
        employeeId: nextEmployeeId,
        createdBy: user.id,
      });

      setEmployeeCounter((prev) => Math.max(prev, nextEmployeeId));
      return docRef.id;
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'employé:", error);
      throw error;
    }
  };

  const updateEmployee = async (id, employeeData) => {
    try {
      await Employees.doc(id).update(employeeData);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'employé:", error);
      throw error;
    }
  };

  const deleteEmployee = async (id) => {
    try {
      const employeeDoc = await Employees.doc(id).get();
      if (employeeDoc.exists) {
        const employeeData = employeeDoc.data();
        setDeletedEmployeeIds((prev) =>
          [...prev, employeeData.employeeId].sort((a, b) => a - b)
        );
        await Employees.doc(id).delete();
      }
    } catch (error) {
      console.error("Erreur lors de la suppression de l'employé:", error);
      throw error;
    }
  };

  const deleteEmployees = async (ids) => {
    const db = firebase.firestore();
    const batch = db.batch();
    const newDeletedIds = [];

    for (const id of ids) {
      const employeeDoc = await Employees.doc(id).get();
      if (employeeDoc.exists) {
        const employeeData = employeeDoc.data();
        newDeletedIds.push(employeeData.employeeId);
        batch.delete(Employees.doc(id));
      }
    }

    try {
      await batch.commit();
      setDeletedEmployeeIds((prev) =>
        [...prev, ...newDeletedIds].sort((a, b) => a - b)
      );
      return true;
    } catch (error) {
      console.error("Erreur lors de la suppression des employés :", error);
      throw error;
    }
  };

  // Fonctions pour les véhicules
  const addVehicle = async (vehicleData) => {
    try {
      const result = await firebase
        .functions()
        .httpsCallable("users-createVehicle")(vehicleData);
    } catch (error) {
      console.error("Erreur lors de l'ajout du véhicule:", error);
      throw error;
    }
  };

  const updateVehicle = async (vehicleData) => {
    try {
      console.log(vehicleData);
      const result = await firebase
        .functions()
        .httpsCallable("users-updateVehicle")(vehicleData);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du véhicule:", error);
      throw error;
    }
  };

  const deleteVehicles = async (ids = []) => {
    try {
      const result = await firebase
        .functions()
        .httpsCallable("users-deleteVehicles")(ids);
    } catch (error) {
      console.error("Erreur lors de la suppression des véhicules :", error);
      throw error;
    }
  };

  // Nouvelle fonction pour lire le prochain ID de véhicule sans l'incrémenter
  const peekNextVehicleId = () => {
    if (deletedVehicleIds.length > 0) {
      // Retourne le plus petit ID supprimé disponible
      return Math.min(...deletedVehicleIds);
    }
    // Sinon retourne le prochain ID séquentiel
    return vehicleCounter + 1;
  };

  const value = {
    employees,
    vehicles,
    loading: loadingEmployees || loadingVehicles,
    addEmployee,
    updateEmployee,
    deleteEmployee,
    deleteEmployees,
    getNextEmployeeId,
    peekNextEmployeeId,
    addVehicle,
    updateVehicle,
    deleteVehicles,
    getNextVehicleId,
    peekNextVehicleId,
  };

  return (
    <RegulatorContext.Provider value={value}>
      {children}
    </RegulatorContext.Provider>
  );
}
