import React, { useState, useMemo, useEffect } from "react";
import {
  ChevronDown,
  Eye,
  EyeOff,
  Plus,
  ChevronUp,
  X,
  Search,
  Trash2,
} from "lucide-react";
import { Checkbox } from "../shadcn/ui/checkbox";
import { DataTable } from "../shadcn/ui/DataTable";
import { DataTablePagination } from "../Table/DataTablePagination";
import Palette from "../../styles/Palette";
import { Button } from "../shadcn/ui/button";
import { RiPencilFill } from "react-icons/ri";
import { EmployeeCombobox } from "../EmployeeCombobox";
import { useRegulator } from "@providers/RegulatorProvider";
import { TeamMate } from "../TeamMate";
import { toast } from "react-hot-toast";
import ReactLoading from "react-loading";

export function VehicleDataTable({ data, onEditVehicle }) {
  const [showPasswords, setShowPasswords] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [activeTab, setActiveTab] = useState("Tous");
  const [search, setSearch] = useState("");
  const { updateVehicle, deleteVehicles, employees } = useRegulator();
  const [loadingTeamMates, setLoadingTeamMates] = useState({});

  // Effet pour réinitialiser la sélection lorsque les données changent
  useEffect(() => {
    console.log("rowSelection :", rowSelection);
  }, [rowSelection]);

  const togglePasswordVisibility = (id) => {
    setShowPasswords((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const filteredData = useMemo(() => {
    let filtered =
      activeTab === "Tous"
        ? data
        : data.filter((vehicle) => vehicle.carType === activeTab);

    if (search.trim()) {
      const searchLower = search.toLowerCase().trim();

      // Créer un Map pour un accès rapide aux employés
      const employeesMap = new Map(
        employees.map((emp) => [
          emp.id,
          `${emp.firstName} ${emp.lastName}`.toLowerCase(),
        ])
      );

      filtered = filtered.filter((vehicle) => {
        // Recherche standard
        const basicMatch =
          (vehicle.name || "").toLowerCase().includes(searchLower) ||
          (vehicle.phoneNumber || "").toLowerCase().includes(searchLower) ||
          (vehicle.mail || "").toLowerCase().includes(searchLower);

        // Recherche par équipier
        const teammate1Name = vehicle.teamMate1Id
          ? employeesMap.get(vehicle.teamMate1Id)
          : "";
        const teammate2Name = vehicle.teamMate2Id
          ? employeesMap.get(vehicle.teamMate2Id)
          : "";
        const teamMatch =
          (teammate1Name || "").includes(searchLower) ||
          (teammate2Name || "").includes(searchLower);

        return basicMatch || teamMatch;
      });
    }

    return filtered;
  }, [data, activeTab, search, employees]);

  // useEffect(() => {
  //   const selectedIds = Object.keys(rowSelection).filter(
  //     (key) => rowSelection[key]
  //   );
  //   console.log(
  //     selectedIds.length > 0
  //       ? `Tableau de lignes VEHICULES mis à jour : ${selectedIds.join(", ")}`
  //       : "Aucune ligne sélectionnée"
  //   );
  //   onRowSelection(selectedIds);
  // }, [rowSelection, onRowSelection]);

  const SortableHeader = ({ column, title }) => {
    // if (filterDisabled) return <div className="font-medium">{title}</div>;

    return (
      <div className="flex items-center">
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="w-full justify-start px-0 font-medium"
        >
          <span>{title}</span>
          <div className="ml-auto flex items-center">
            {column.getIsSorted() && (
              <>
                {column.getIsSorted() === "asc" ? (
                  <ChevronUp className="h-4 w-4" />
                ) : (
                  <ChevronDown className="h-4 w-4" />
                )}
                <Button
                  variant="ghost"
                  onClick={(e) => {
                    e.stopPropagation();
                    column.clearSorting();
                  }}
                  className="p-0 h-8 w-8 ml-1"
                >
                  <X className="h-4 w-4" />
                </Button>
              </>
            )}
          </div>
        </Button>
      </div>
    );
  };

  const renderTeamMate = (teamMateId, index) => (
    <TeamMate teamMateId={teamMateId} index={index} />
  );

  const renderAddTeamMate = (row, teamMateKey, filtered) => (
    <EmployeeCombobox
      onSelect={async (employee) => {
        const loadingKey = `${row.original.id}-${teamMateKey}`;
        try {
          setLoadingTeamMates((prev) => ({ ...prev, [loadingKey]: true }));
          await updateVehicle({
            [teamMateKey]: employee.id,
            id: row.original.id,
          });
        } catch (error) {
          console.error("Erreur lors de l'assignation de l'équipier :", error);
          toast.error(`Erreur : ${error.message}`);
        } finally {
          setLoadingTeamMates((prev) => ({ ...prev, [loadingKey]: false }));
        }
      }}
      filtered={filtered}
      triggerElement={
        loadingTeamMates[`${row.original.id}-${teamMateKey}`] ? (
          <ReactLoading
            type="spin"
            color={Palette.blue}
            height={20}
            width={20}
          />
        ) : (
          <div
            style={{
              backgroundColor: Palette.greyBackground,
              height: 20,
              width: 20,
              border: `1.5px dashed ${Palette.darkBlue}`,
              alignItems: "center",
              borderRadius: 6,
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Plus className="h-3 w-3 text-grey-900" />
          </div>
        )
      }
    />
  );

  const columns = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "carType",
      header: ({ column }) => <SortableHeader column={column} title="TYPE" />,
      cell: ({ row }) => (
        <div className="flex flex-col justify-center">
          {row.original.carType === "AMBULANCE" ? (
            <>
              <img
                src="/icons/ambulance.png"
                className="h-6 w-6 text-nightBlue"
              />
              <span
                className="mt-1 text-xs font-medium italic"
                style={{ color: Palette.blueItemBackground }}
              >
                AMB
              </span>
            </>
          ) : (
            <>
              <img src="/icons/car.png" className="h-6 w-6" />
              <span className="mt-1 text-yellow-500 text-xs font-medium italic">
                VSL
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <SortableHeader column={column} title="IMMATRICULATION" />
      ),
      cell: ({ row }) => (
        <div className="font-semibold text-nightBlue">
          {row.getValue("name") || "Non enregistré"}
        </div>
      ),
    },
    {
      accessorKey: "teamMate1Id",
      header: ({ column }) => (
        <SortableHeader column={column} title="EQUIPIER 1" />
      ),
      cell: ({ row }) => renderTeamMate(row.original.teamMate1Id, 1),
    },
    {
      accessorKey: "addTeamMate1Id",
      header: ({ column }) => <SortableHeader column={column} title="" />,
      cell: ({ row }) => renderAddTeamMate(row, "teamMate1Id", false), // No filter for Equipier 1
    },
    {
      accessorKey: "teamMate2Id",
      header: ({ column }) => (
        <SortableHeader column={column} title="EQUIPIER 2 - DEA" />
      ),
      cell: ({ row }) =>
        row.original.carType !== "VSL" &&
        renderTeamMate(row.original.teamMate2Id, 2),
    },
    {
      accessorKey: "addTeamMate2Id",
      header: ({ column }) => <SortableHeader column={column} title="" />,
      cell: ({ row }) =>
        row.original.carType !== "VSL" &&
        renderAddTeamMate(row, "teamMate2Id", true), // No filter for Equipier 2
    },
    {
      accessorKey: "phoneNumber",
      header: ({ column }) => (
        <SortableHeader column={column} title="NUMÉRO DE TEL" />
      ),
      cell: ({ row }) => (
        <div className="font-semibold">
          {row.getValue("phoneNumber") || "Non enregistré"}
        </div>
      ),
    },
    {
      accessorKey: "mail",
      header: ({ column }) => <SortableHeader column={column} title="EMAIL" />,
      cell: ({ row }) => (
        <div className="font-semibold">
          {row.getValue("mail") || "Non enregistré"}
        </div>
      ),
    },
    {
      accessorKey: "password",
      header: "MOT DE PASSE",
      cell: ({ row }) => {
        const vehicle = row.original;
        return (
          <div className="flex items-center font-semibold">
            <span>
              {vehicle.password
                ? showPasswords[vehicle.id]
                  ? vehicle.password
                  : "••••••••"
                : "Non enregistré"}
            </span>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => togglePasswordVisibility(vehicle.id)}
              className="ml-2"
            >
              {showPasswords[vehicle.id] ? (
                <EyeOff className="h-4 w-4" />
              ) : (
                <Eye className="h-4 w-4" />
              )}
            </Button>
          </div>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => (
        <Button
          variant="ghost"
          className="h-6 w-6 p-0"
          style={{ backgroundColor: "rgba(73, 81, 255, 0.1)" }}
          onClick={() => onEditVehicle(row.original)}
        >
          <RiPencilFill
            className="h-4 w-4"
            style={{ color: Palette.blueItemBackground }}
          />
        </Button>
      ),
    },
  ];

  const handleDeleteSelected = async () => {
    try {
      await deleteVehicles(Object.keys(rowSelection));
      setRowSelection({});
      toast.success("Véhicule(s) supprimé(s) avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression des véhicules:", error);
      toast.error("Erreur lors de la suppression des véhicules");
    }
  };

  const renderTabs = () => {
    const tabs = [
      { key: "Tous", count: data.length },
      {
        key: "AMBULANCE",
        count: data.filter((v) => v.carType === "AMBULANCE").length,
      },
      { key: "VSL", count: data.filter((v) => v.carType === "VSL").length },
    ];

    return (
      <div className="mb-4 flex space-x-4 font-medium">
        {tabs.map(({ key, count }) => (
          <button
            key={key}
            className={`pb-2 ${
              activeTab === key
                ? "border-b-2 border-nightBlue text-nightBlue font-bold"
                : "text-gray-600"
            }`}
            onClick={() => setActiveTab(key)}
          >
            {key}{" "}
            <span
              className={`ml-1 px-2 py-1 ${
                key === "Tous" ? "bg-blue-100" : "bg-gray-100"
              } text-blue-800 rounded-full text-xs`}
            >
              {count}
            </span>
          </button>
        ))}
      </div>
    );
  };

  const renderActionButtons = () => (
    <div className="flex justify-end">
      <button
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:opacity-80 flex items-center mr-2 max-w-56"
        onClick={() => onEditVehicle(null)}
      >
        <Plus className="mr-2" size={20} />
        Nouveau véhicule
      </button>
    </div>
  );

  return (
    <div>
      <div className="flex items-center mb-4 justify-between">
        <div className="flex items-center">
          <button
            className={`p-2 text-gray-500 hover:text-gray-700 border rounded-md ${
              Object.keys(rowSelection).length === 0
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            onClick={handleDeleteSelected}
            disabled={Object.keys(rowSelection).length === 0}
          >
            <Trash2 size={20} />
          </button>
          <div className="mx-2 border rounded-md flex items-center max-w-96">
            <button className="p-2 text-gray-500 hover:text-gray-700 border-r-2">
              <Search className="text-gray-400" size={20} />
            </button>
            <input
              type="text"
              placeholder="Rechercher"
              className="w-40 pl-2 pr-4 py-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        {renderActionButtons()}
      </div>
      {renderTabs()}
      <div className="rounded-md border">
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          paginationComponent={DataTablePagination}
          onRowSelectionChange={setRowSelection}
          enableRowSelection={true}
          state={{ rowSelection }}
          enableSorting={true}
          enableColumnFilters={true}
          getRowId={(row) => row.id}
        />
      </div>
    </div>
  );
}
