import React, { useState } from "react";
import LayoutAuth from "../../layouts/auth";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../../providers/Auth";
import { firebaseErrors } from "../../actions/ManageErrors";

export default function Login() {
  const location = useLocation();
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { from } = location.state || { from: { pathname: "/transports" } };

  const login = async () => {
    try {
      await auth.signIn({ email, password });
    } catch (e) {
      alert(firebaseErrors(e.code));
    }
  };

  if (auth.user) {
    return <Redirect to={from} />;
  }

  return (
    <LayoutAuth>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 56,
        }}
      >
        <h1 style={{ marginBottom: 25 }}>Bienvenue</h1>
      </div>
      <Input
        value={email}
        onChange={setEmail}
        placeholder={"Identifiant"}
        imgLeft={"/icons/smile.png"}
        style={{ marginBottom: 30 }}
        containerStyle={{ width: "100%" }}
      />
      <Input
        value={password}
        onChange={setPassword}
        type={"password"}
        placeholder={"Mot de passe"}
        imgLeft={"/icons/lock.png"}
        iconRight={"/icons/visible.png"}
        iconRightStyle={{ width: 30, height: 30, cursor: "pointer" }}
        style={{ marginBottom: 120 }}
        containerStyle={{ width: "100%" }}
      />
      <Button size="big" fullWidth text={"Se connecter"} onClick={login} />
    </LayoutAuth>
  );
}
