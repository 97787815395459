import Palette from "./Palette";
import Colors from "./Colors";

const Global = {
  shadow: {
    WebkitBoxShadow: "0px 0px 2px 1px rgba(0,0,0,0.10)",
    boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.20)",
  },
  backShadow: {
    WebkitBoxShadow: "0px 0px 10px 1px rgba(0,0,0,0.10)",
    boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.10)",
  },
  bigShadow: {
    WebkitBoxShadow: "0px 0px 10px 1px rgba(0,0,0,0.10)",
    boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.10)",
  },
  containerItem: {
    padding: 20,
    boxSizing: "border-box",
    backgroundColor: Palette.white,
    borderRadius: 5,
  },
  box: {
    boxSizing: "border-box",
    padding: 15,
    borderRadius: 5,
    border: `1px solid ${Colors.input.border}`,
  },
  item: {
    display: "flex",
    backgroundColor: Palette.white,
    boxSizing: "border-box",
    height: 44,
    borderRadius: 10,
    alignItems: "center",
    fontSize: 14,
    fontWeight: "600",
    color: "rgba(2,25,108,0.6)",
  },
};

export default Global;
