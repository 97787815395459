import { useZustandStore } from "@helpers/store";
import React from "react";
import { useHistory } from "react-router-dom";
import { setGlobal } from "reactn";
import PatientCard2 from "./PatientCard2";
import PlanningTransBarProgress from "./PlanningTransBarProgress";

function PlanningFooter() {
  const history = useHistory();
  const planningTransportDetail =
    useZustandStore((state) => state.planningTransportDetail) ?? {};
  const { patient, from, to, id } = planningTransportDetail ?? {};
  const handleCancel = () => {};

  const handleDetail = () => {
    history.push({
      search: `?transport=${id}`
    });
  };

  return (
    <div className="flex flex-row max-h-[110px] rounded-b-[1.5rem] bg-shadyGray2 border-t-[3px] border-rgb(222, 224, 243)">
      {patient && (
        <>
          <div className="w-[25%] justify-center">
            {patient && <PatientCard2 patient={patient} from={from} to={to} />}
          </div>
          <div className="flex-[0_0_1%]">
            <img
              width={3}
              src="icons/planning_filter/line_vertival.svg"
              alt=""
            />
          </div>
          <div className="flex flex-col w-[50%]">
            <div className="mt-2">
              <PlanningTransBarProgress transport={planningTransportDetail} />
            </div>
            <div className="flex flex-row">
              <div className="w-[40%]">
                <div className="flex flex-row">
                  <div className="flex justify-start items-center w-[10%]">
                    <img width={25} src="icons/planning_filter/domicile.svg" />
                  </div>
                  <div className="text-skyBlue2 leading-3 w-[90%] self-end">
                    Domicile
                  </div>
                </div>

                <div className="leading-1 text-[12px] text-lightBlue">
                  {from?.address} - {from?.postalCode} {from?.city}
                </div>
                <div className="leading-1 text-[12px] text-pink">
                  {from?.establishment} {from?.chamber}
                </div>
              </div>
              <div className="w-[20%] flex justify-center items-center">
                <img
                  style={{ maxWidth: "300", maxHeight: "46px" }}
                  src="icons/planning_filter/depart_arrive.png"
                />
              </div>
              <div className="w-[40%]">
                <div className="flex flex-row">
                  <div className="text-skyBlue2 leading-3 w-[90%] self-end text-end pr-1">
                    {to?.name}
                  </div>
                  <div className="flex justify-end items-center w-[10%]">
                    <img width={25} src="icons/planning_filter/hopital.svg" />
                  </div>
                </div>

                <div className="leading-1 text-[12px] text-lightBlue text-end">
                  {to?.address} - {to?.postalCode} {to?.city}
                </div>
                <div className="leading-1 text-[12px] text-pink text-end">
                  {to?.establishment} {to?.chamber}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-[0_0_1%]  ml-[10px]">
            <img
              width={3}
              src="icons/planning_filter/line_vertival.svg"
              alt=""
            />
          </div>
          <div className="flex-[0_0_12%] flex flex-col items-center justify-evenly">
            <div>
              <button
                className="bg-pink text-white w-[120px] h-[30px] text-[12px] rounded-[3px]"
                onClick={() =>
                  setGlobal({
                    transportToCancelOrBourse: planningTransportDetail
                  })
                }
              >
                Annuler
              </button>
            </div>
            <div>
              <button
                className="bg-nightBlue text-white w-[120px] h-[30px] text-[12px] rounded-[3px]"
                onClick={handleDetail}
              >
                Voir en détail
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PlanningFooter;
