export const MAP_CONFIG = {
  DEFAULT_CENTER: {
    lat: 46.71109,
    lng: 1.7191036,
  },
  DEFAULT_ZOOM: 7,
  API_KEY: "AIzaSyA9nYMER9hfuG9SIwDT836vfGG4vjzS79o",
  LIBRARIES: ["geometry", "places"],
  MARKER_SIZE: 32,
  INFO_OFFSET_Y: 5,
  COLORS: {
    VSL: "#F59E0B",
    AMBULANCE: "#3B82F6",
    TEXT: "#FFFFFF",
    AVAILABLE: "#22C55E",
    UNAVAILABLE: "#EF4444",
    ASSIGNED: "#8B5CF6",
    INACTIVE: "#9CA3AF",
  },
  VEHICLE_STATES: {
    AVAILABLE: "AVAILABLE",
    UNAVAILABLE: "UNAVAILABLE",
    ASSIGNED: "ASSIGNED",
    INACTIVE: "INACTIVE",
  },
  CLUSTER: {
    RADIUS: 80,
    MAX_ZOOM: 20,
    MIN_ZOOM: 3,
    DISPLAY_MARKERS_ZOOM: 16,
  },
  UPDATE_INTERVAL: 5000,
};

export const MAP_STYLES = {
  MARKER: {
    container: `
      position: relative;
      width: ${MAP_CONFIG.MARKER_SIZE}px;
      height: ${MAP_CONFIG.MARKER_SIZE}px;
      z-index: 1;
      display: block;
      cursor: pointer;
    `,
    rotation: `
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform-origin: center center;
    `,
    image: `
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: contain;
    `,
  },
  INFO: `
    background: white;
    padding: 4px 12px;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    white-space: nowrap;
    pointer-events: none;
    z-index: 1000;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    position: absolute;
    transform: translate(-50%, -100%);
  `,
};
