import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../shadcn/ui/card";
import { Button } from "../shadcn/ui/button";
import { Input } from "../shadcn/ui/input";
import { Label } from "../shadcn/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../shadcn/ui/select";
import { UserIcon, UserPlusIcon, Trash2Icon, Loader2 } from "lucide-react"; // Ajout de Loader2
import { Badge } from "../shadcn/ui/badge";
import { DatePicker } from "../Picker/DatePicker";
import InputPlacesAuto from "../InputPlacesAuto";
import { toast } from "react-hot-toast";
import { useRegulator } from "@providers/RegulatorProvider";
import IconWrapper from "../IconWrapper";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import styles from "./AmbulancierForm.module.css";
import vehicles from "@constants/vehicles";

function AmbulancierForm({ onClose, employee = {} }) {
  const { addEmployee, updateEmployee, deleteEmployee, peekNextEmployeeId } =
    useRegulator();
  const [selectedType, setSelectedType] = useState(employee?.type || "DEA");
  const [loading, setLoading] = useState(false);
  const [displayId, setDisplayId] = useState("..."); // Valeur par défaut pendant le chargement

  // Effet simplifié qui ne s'exécute qu'une seule fois
  useEffect(() => {
    // Si c'est un employé existant, on affiche son ID
    if (employee?.id) {
      setDisplayId(employee.employeeId);
    }
    // Si c'est un nouvel employé, on affiche le prochain ID disponible
    else {
      // Utilisation de peekNextEmployeeId qui est synchrone et ne modifie pas l'état
      setDisplayId(peekNextEmployeeId());
    }
  }, [employee?.id]); // Dépendance uniquement sur l'existence d'un ID d'employé

  // Fonction modifiée pour gérer tous les cas de dates
  const formatBirthDate = (birthDate) => {
    if (!birthDate) return null;

    try {
      if (birthDate instanceof firebase.firestore.Timestamp) {
        return birthDate.toDate();
      }

      if (birthDate instanceof Date) {
        return birthDate;
      }

      // Si c'est une chaîne de caractères, on essaie de la convertir en Date
      if (typeof birthDate === "string") {
        const date = new Date(birthDate);
        return isNaN(date.getTime()) ? null : date;
      }

      return null;
    } catch (error) {
      console.error("Erreur lors du formatage de la date:", error);
      return null;
    }
  };

  // const isDevelopment = process.env.REACT_APP_USE_FIREBASE_EMULATOR === "true";
  const isDevelopment = false;

  const defaultFormData = isDevelopment
    ? {
        type: "DEA",
        gender: "male",
        lastName: "Dupont",
        firstName: "Jean",
        birthDate: "1990-01-01",
        socialSecurity: "1900175789456",
        address: {
          street: "123 Rue de la Paix",
          postalCode: "75001",
          city: "Paris",
        },
        phone: "0612345678",
        documents: {
          diploma: "",
          drivingLicense: "",
          healthCard: "",
          rib: "",
        },
      }
    : {
        type: "DEA",
        gender: "male",
        lastName: "",
        firstName: "",
        birthDate: "",
        socialSecurity: "",
        address: {
          street: "",
          postalCode: "",
          city: "",
        },
        phone: "",
        documents: {
          diploma: "",
          drivingLicense: "",
          healthCard: "",
          rib: "",
        },
      };

  const [formData, setFormData] = useState(() => {
    if (employee && Object.keys(employee).length > 0) {
      return {
        ...defaultFormData,
        ...employee,
        type: employee?.type || "DEA",
        address: { ...defaultFormData.address, ...(employee.address || {}) },
        documents: {
          ...defaultFormData.documents,
          ...(employee.documents || {}),
        },
      };
    }
    return defaultFormData;
  });

  const [documents, setDocuments] = useState(() => {
    if (employee && employee.documents) {
      return {
        diploma: employee.documents.diploma || "",
        drivingLicense: employee.documents.drivingLicense || "",
        healthCard: employee.documents.healthCard || "",
        rib: employee.documents.rib || "",
      };
    }
    return {
      diploma: "",
      drivingLicense: "",
      healthCard: "",
      rib: "",
    };
  });

  useEffect(() => {
    if (employee && Object.keys(employee).length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        ...employee,
        type: employee?.type || "DEA",
        address: { ...prevData.address, ...(employee.address || {}) },
        documents: { ...prevData.documents, ...(employee.documents || {}) },
      }));
      setSelectedType(employee?.type || "DEA");
    }
  }, [employee]);

  const fileInputRefs = {
    diploma: useRef(null),
    drivingLicense: useRef(null),
    healthCard: useRef(null),
    rib: useRef(null),
  };

  const handleAutocomplete = ({ streetNumber, route, city, postalCode }) => {
    setFormData((prev) => ({
      ...prev,
      address: {
        street: `${streetNumber || ""} ${route || ""}`,
        postalCode: postalCode || "",
        city: city || "",
      },
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "street" || id === "postalCode" || id === "city") {
      setFormData((prev) => ({
        ...prev,
        address: { ...prev.address, [id]: value },
      }));
    } else if (id === "firstName" || id === "lastName") {
      const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
      setFormData((prev) => ({ ...prev, [id]: capitalizedValue }));
    } else if (id === "phone") {
      // Limiter à 10 chiffres et ne permettre que des chiffres
      const phoneNumber = value.replace(/\D/g, "").slice(0, 10);
      setFormData((prev) => ({ ...prev, [id]: phoneNumber }));
    } else if (id === "socialSecurity") {
      // Limiter à 13 chiffres et ne permettre que des chiffres
      const socialSecurityNumber = value.replace(/\D/g, "").slice(0, 13);
      setFormData((prev) => ({ ...prev, [id]: socialSecurityNumber }));
    } else {
      setFormData((prev) => ({ ...prev, [id]: value }));
    }
  };

  const handleTypeChange = (type) => {
    console.log(type);
    setSelectedType(type);
    setFormData((prev) => ({ ...prev, type }));
  };

  const handleDocumentChange = (documentType) => {
    if (documents[documentType]) {
      // Si le document existe déjà, on le supprime
      setDocuments((prev) => ({ ...prev, [documentType]: "" }));
      setFormData((prevEmployee) => ({
        ...prevEmployee,
        documents: { ...prevEmployee.documents, [documentType]: "" },
      }));
    } else {
      // Sinon, on ouvre le sélecteur de fichier
      fileInputRefs[documentType].current.click();
    }
  };

  const handleFileChange = async (documentType, event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "application/pdf"];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (
        allowedTypes.includes(file.type) ||
        (fileExtension === "jpg" && file.type === "image/jpeg")
      ) {
        try {
          setLoading(true);

          // Générer un ID temporaire si l'employé n'en a pas encore
          const employeeId = formData.id || `temp_${Date.now()}`;

          // Créer un nom de fichier unique
          const fileName = `Employee_${employeeId}_${documentType}_${Date.now()}.${fileExtension}`;
          const filePath = `employees/${employeeId}/${fileName}`;

          // Uploader le fichier à Firebase Storage
          const storageRef = firebase.storage().ref(filePath);
          await storageRef.put(file);

          // Obtenir l'URL de téléchargement
          const downloadURL = await storageRef.getDownloadURL();

          // Mettre à jour l'état local
          setDocuments((prev) => ({ ...prev, [documentType]: downloadURL }));
          setFormData((prevEmployee) => ({
            ...prevEmployee,
            documents: {
              ...prevEmployee.documents,
              [documentType]: downloadURL,
            },
          }));

          // Ne pas mettre à jour Firestore ici, nous le ferons lors de la soumission du formulaire

          toast.success(`Document ${documentType} uploadé avec succès`);
        } catch (error) {
          console.error("Erreur lors de l'upload:", error);
          toast.error("Erreur lors de l'upload du document");
        } finally {
          setLoading(false);
        }
      } else {
        toast.error(
          "Type de fichier non autorisé. Veuillez sélectionner un fichier .png, .jpeg, .jpg ou .pdf."
        );
        event.target.value = "";
      }
    }
  };

  const validateForm = () => {
    // Mapping des champs avec leurs placeholders
    const fieldPlaceholders = {
      type: "Type",
      gender: "Sexe",
      lastName: "Nom",
      firstName: "Prénom",
      birthDate: "D.naissance",
      socialSecurity: "NSS",
      phone: "Numéro Tel",
      street: "Rue",
      postalCode: "Code postal",
      city: "Ville",
    };

    // Vérification des champs obligatoires
    const requiredFields = [
      "type",
      "gender",
      "lastName",
      "firstName",
      "birthDate",
      "socialSecurity",
      "phone",
    ];
    const addressFields = ["street", "postalCode", "city"];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        throw new Error(
          `Le champ ${fieldPlaceholders[field]} est obligatoire.`
        );
      }
    });

    // Vérification spécifique pour le champ type
    if (formData.type !== "DEA" && formData.type !== "AUX") {
      throw new Error("Le type doit être soit 'DEA' soit 'AUX'.");
    }

    addressFields.forEach((field) => {
      if (!formData.address[field]) {
        throw new Error(
          `Le champ ${fieldPlaceholders[field]} est obligatoire.`
        );
      }
    });

    // Vérification du numéro de sécurité sociale
    if (formData.socialSecurity.length < 13) {
      throw new Error(
        `Le ${fieldPlaceholders.socialSecurity} doit contenir au moins 13 chiffres.`
      );
    }

    // Vérification du numéro de téléphone
    if (formData.phone.length !== 10) {
      throw new Error(
        `Le ${fieldPlaceholders.phone} doit contenir exactement 10 chiffres.`
      );
    }
  };

  const handleSubmit = async () => {
    try {
      validateForm();

      const employeeData = {
        ...formData,
        type: selectedType,
        documents: { ...documents },
        address: { ...formData.address },
      };

      if (formData.id) {
        await updateEmployee(formData.id, employeeData);
        toast.success("Informations de l'employé mises à jour avec succès !");
      } else {
        // On ne passe pas l'ID au provider, il le gérera lui-même
        await addEmployee(employeeData);
        toast.success(`Employé ajouté avec succès !`);
      }

      onClose();
    } catch (error) {
      console.error("Erreur lors de la soumission:", error);
      toast.error(`Erreur : ${error.message}`);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteEmployee(formData.id);
      toast.success("Employé supprimé avec succès !");
      console.log("Suppression de l'employé");
      onClose();
    } catch (error) {
      console.error("Erreur lors de la suppression:", error);
      toast.error(`Erreur : ${error.message}`);
    }
  };

  return (
    <Card className="w-[400px] bg-white shadow-lg rounded-2xl overflow-hidden">
      <CardHeader
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <Badge
            style={{
              borderRadius: 9999,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 8,
            }}
            className="bg-borderV2 text-nightBlue"
          >
            <span style={{ fontWeight: "600", fontSize: 14 }}>{displayId}</span>
          </Badge>
          <CardTitle style={{ fontSize: 24, fontWeight: "700" }}>
            Ambulancier
          </CardTitle>
        </div>
        <div style={{ display: "flex", gap: 8 }}>
          <Button
            style={{
              borderRadius: 16,
              backgroundColor: selectedType === "DEA" ? "#F7F9FC" : "#F6F7FF",
              //TODO: adapt hexa
              padding: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 50,
              height: 50,
              border:
                selectedType === "DEA"
                  ? "2px solid #02196C"
                  : "1px solid #DEE0F3",
              //TODO: adapt hexa
            }}
            onClick={() => handleTypeChange("DEA")}
          >
            <IconWrapper
              icon={UserPlusIcon}
              size={18}
              className="text-nightBlue"
              strokeWidth={2.5}
            />
            <span className="text-[10px] font-semibold text-redDEA italic">
              DEA
            </span>
          </Button>
          <Button
            style={{
              borderRadius: 16,
              backgroundColor: selectedType === "AUX" ? "#F7F9FC" : "#F6F7FF",
              padding: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: 50,
              height: 50,
              border:
                selectedType === "AUX"
                  ? "2px solid #02196C"
                  : "1px solid #DEE0F3",
              //TODO: adapt hexa
            }}
            onClick={() => handleTypeChange("AUX")}
          >
            <IconWrapper
              icon={UserIcon}
              size={18}
              className="text-nightBlue"
              strokeWidth={2.5}
            />
            <span className="text-[10px] font-semibold text-[#6B7280] italic">
              AUX
            </span>
          </Button>
        </div>
      </CardHeader>
      <CardContent className="p-4 space-y-4">
        <div className="grid grid-cols-8 gap-3">
          <div className="col-span-2">
            <Label htmlFor="gender" className="text-formGrey">
              Sexe
            </Label>
            <Select
              onValueChange={(value) =>
                setFormData((prev) => ({ ...prev, gender: value }))
              }
            >
              <SelectTrigger id="gender">
                <SelectValue placeholder="M" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="male">M</SelectItem>
                <SelectItem value="female">Mme</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="col-span-3">
            <Label htmlFor="lastName" className="text-formGrey">
              Nom
            </Label>
            <Input
              id="lastName"
              placeholder="Nom"
              value={formData.lastName || ""}
              onChange={handleInputChange}
              className="h-10"
            />
          </div>
          <div className="col-span-3">
            <Label htmlFor="firstName" className="text-formGrey">
              Prénom
            </Label>
            <Input
              id="firstName"
              placeholder="Prénom"
              value={formData.firstName || ""}
              onChange={handleInputChange}
              className="h-10"
            />
          </div>
        </div>

        <div className="grid grid-cols-7 gap-4">
          <div className="col-span-3">
            <Label htmlFor="birthDate" className="text-formGrey">
              Date de naissance
            </Label>
            <DatePicker
              placeholder="D.naissance"
              date={formatBirthDate(formData.birthDate)}
              setDate={(value) =>
                setFormData((prev) => ({ ...prev, birthDate: value }))
              }
              className="w-full"
            />
          </div>
          <div className="col-span-4">
            <Label htmlFor="socialSecurity" className="text-formGrey">
              N°Sécurité Sociale
            </Label>
            <Input
              id="socialSecurity"
              placeholder="NSS"
              type="number"
              className={`${styles["no-spinner"]}`}
              maxLength={13}
              value={formData.socialSecurity}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div>
          <Label htmlFor="street" className="text-formGrey">
            Adresse & Téléphone
          </Label>
          <InputPlacesAuto
            required
            placeholder="Rue"
            style={{ marginBottom: 10 }}
            value={formData?.address?.street || ""}
            onChange={(value) =>
              handleInputChange({ target: { id: "street", value } })
            }
            onSelect={handleAutocomplete}
            iconRight="/icons/search.png"
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <Input
              id="postalCode"
              placeholder="Code postal"
              value={formData?.address?.postalCode || ""}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <Input
              id="city"
              placeholder="Ville"
              value={formData?.address?.city || ""}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <Input
              id="phone"
              placeholder="Numéro Tel"
              value={formData.phone}
              onChange={handleInputChange}
              maxLength={10}
              type="tel"
              pattern="[0-9]*"
            />
          </div>
        </div>
        <div>
          <Label className="text-formGrey">Documents</Label>
          <div className="mt-2 grid grid-cols-2 gap-2">
            {Object.entries(documents).map(([docType, url]) => (
              <React.Fragment key={docType}>
                <Button
                  variant="outline"
                  className={`justify-between ${
                    url
                      ? "bg-greenValid text-greenValid-text"
                      : "bg-blueWaiting"
                  } border-2 
                  ${
                    url
                      ? "border-greenValid-border"
                      : "border-blueWaiting-border"
                  }`}
                  onClick={() => handleDocumentChange(docType)}
                  disabled={loading}
                >
                  <span
                    className={`font-bold ${
                      url ? "text-greenValid-text" : "text-blueWaiting-text"
                    }`}
                  >
                    {docType === "diploma" && "Diplôme"}
                    {docType === "drivingLicense" && "Permis"}
                    {docType === "healthCard" && "Carte vitale"}
                    {docType === "rib" && "RIB"}
                  </span>
                  {loading ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : url ? (
                    <span>✓</span>
                  ) : (
                    <span className="text-lg font-regular text-blueWaiting-text">
                      +
                    </span>
                  )}
                </Button>
                <input
                  type="file"
                  ref={fileInputRefs[docType]}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(docType, e)}
                  accept=".png,.jpeg,.jpg,.pdf"
                  disabled={loading}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </CardContent>
      <CardFooter
        style={{
          padding: 16,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: 16 }}>
          <Button
            variant="outline"
            onClick={onClose}
            style={{ borderColor: "#D1D5DB", color: "#374151" }}
            disabled={loading}
          >
            Fermer
          </Button>
          <Button
            onClick={handleSubmit}
            style={{ backgroundColor: "#2563EB", color: "white" }}
            disabled={loading}
          >
            {loading ? <Loader2 className="h-4 w-4 animate-spin mr-2" /> : null}
            Valider
          </Button>
        </div>
        {formData.id && ( // Modifiez cette ligne
          <Button
            variant="ghost"
            size="icon"
            style={{ backgroundColor: "#FEE2E2" }}
            onClick={handleDelete}
            disabled={loading}
          >
            <Trash2Icon style={{ height: 24, width: 24, color: "#EF4444" }} />
          </Button>
        )}
      </CardFooter>
    </Card>
  );
}

export default AmbulancierForm;
