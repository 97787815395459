import Moment from "moment";
import React from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

function CalendarDayView({ containerStyle, actualDate, setActualDate }) {
  const moment = Moment;

  return (
    <div
      className="containerRowCenter"
      style={{
        ...containerStyle,
        display: "flex",
        alignItems: "center",
        gap: 20
      }}
    >
      {/* Left Arrow */}
      <button
        onClick={() =>
          setActualDate(moment(actualDate.clone().subtract(1, "day")))
        }
        style={{
          border: "1px solid #ccc",
          borderRadius: "30%",
          width: 35,
          height: 35,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "transparent",
          cursor: "pointer"
        }}
      >
        <RiArrowLeftSLine size={20} color="#333" />
      </button>

      {/* Date Display */}
      <div style={{ fontSize: 18, fontWeight: "bold", color: "#222" }}>
        {actualDate.format("dddd DD MMMM YYYY")}
      </div>

      {/* Right Arrow */}
      <button
        onClick={() => setActualDate(moment(actualDate.clone().add(1, "day")))}
        style={{
          border: "1px solid #ccc",
          borderRadius: "30%",
          width: 35,
          height: 35,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "transparent",
          cursor: "pointer"
        }}
      >
        <RiArrowRightSLine size={20} color="#333" />
      </button>
    </div>
  );
}

export default CalendarDayView;
