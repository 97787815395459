import Drawer from "@components/Drawer/Drawer";
import TabBar from "@components/TabBar";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import "./style.css";

import { useZustandStore } from "@helpers/store";
import Global from "@styles/Global";

export const useGutters = (noPadding) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const calcGutters = isMobile ? 16 : isTabletOrMobile ? 50 : 100;
  return !noPadding ? calcGutters : 0;
};

export default function LayoutMain({
  containerStyle = {},
  style,
  children,
  noPadding = false,
  onRight,
}) {
  const isDrawerOpen = useZustandStore((state) => state.isDrawerOpen);
  const setIsDrawerOpen = useZustandStore((state) => state.setIsDrawerOpen);
  const setGlobalRef = useZustandStore((state) => state.setGlobalRef);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const location = useLocation();

  const isMapPage = location.pathname === "/map";
  const padding = isMobile ? 16 : 24;
  const tabBarHeight = "90vh";

  return (
    <div
      style={{
        backgroundColor: "#FBFBFD",
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: isMapPage ? 0 : `${padding}px 0`,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: "100%",
          padding: isMapPage ? 0 : `0 ${padding}px`,
          boxSizing: "border-box",
          position: "relative",
          display: "flex",
          height: isMapPage ? "100vh" : "auto",
        }}
      >
        {/* TabBar Container */}
        <div
          style={{
            position: "fixed",
            left: isMapPage ? padding : padding,
            height: isMapPage ? "100vh" : tabBarHeight,
            width: "80px",
            display: "flex",
            alignItems: "center",
            zIndex: 10,
            top: isMapPage ? 0 : "auto",
          }}
        >
          <TabBar />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={(e) => {
              setIsDrawerOpen(false);
              setGlobalRef(e.target);
            }}
          />
        </div>

        {/* Spacer to compensate for fixed TabBar */}
        <div style={{ width: "80px", flexShrink: 0 }} />

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            marginLeft: isMapPage ? 0 : "24px",
            border: isMapPage ? "none" : "solid 2px #DEE0F3",
            borderRadius: isMapPage ? 0 : 25,
            backgroundColor: "white",
            height: isMapPage ? "100vh" : tabBarHeight,
            ...(!isMapPage && Global.shadow),
            // overflow: "auto",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
