import { dataDropdown } from "@helpers/dataDropdown";
import { useZustandStore } from "@helpers/store";
import { useAuth } from "@providers/Auth";
import Palette from "@styles/Palette";
import moment from "moment";
import React, { useEffect, useState } from "react";
import TransportRegulationDropDown from "./TransportRegulationDropDown";
import TransportRegulationFilter from "./TransportRegulationFilter";
import TransportRegulationList from "./TransportRegulationList";
function TransportRegulation({ setSubNavBarIsOpen, transportLists }) {
  const setGlobalRef = useZustandStore((state) => state.setGlobalRef);
  const { user } = useAuth();
  const { transportStatusData, transportStatusHospitalData } = dataDropdown;
  const [selected, setSelected] = useState(
    user?.role === "HOSPITAL"
      ? [transportStatusHospitalData[1]] // Initial selection for HOSPITAL
      : [transportStatusData[1]] // Initial selection for other roles
  );
  const [searchFilter, setSearchFilter] = useState({
    carType: ""
  });

  const [filterState, setFilterState] = useState([false, false, moment()._d]);

  const handleFilter = (index) => {
    setFilterState((prevStates) => {
      const newState = [...prevStates];
      if (index === 0 || index === 1) {
        newState[index] = !newState[index];
        if (newState[0] && newState[1]) {
          newState[index === 0 ? 1 : 0] = false;
        } else if (newState[1]) {
          newState[0] = false;
        } else if (newState[0]) {
          newState[1] = false;
        }
      }
      return newState;
    });
  };
  const handleDateFilter = (date) => {
    setFilterState((prevStates) => {
      const newState = [...prevStates];
      newState[2] = date;
      return newState;
    });
  };
  useEffect(() => {
    if (!filterState[0] && !filterState[1]) {
      setSearchFilter((prev) => ({ ...prev, carType: "" }));
    }
  }, [filterState]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const data =
    user?.role === "HOSPITAL"
      ? transportStatusHospitalData
      : transportStatusData;

  function removeTousIfMultipleStatuses(statuses) {
    const names = statuses.map((status) => status.name);
    if (statuses.length > 1 && names.includes("Tous")) {
      return statuses.filter((status) => status.name !== "Tous");
    }

    return statuses;
  }

  useEffect(() => {
    const newSelected = removeTousIfMultipleStatuses(selected);

    // Only update the state if it's different from the current state
    if (JSON.stringify(newSelected) !== JSON.stringify(selected)) {
      setSelected(newSelected);
    }
  }, [selected]);
  return (
    <div onClick={(e) => setGlobalRef(e.target)}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100px important",
          marginBottom: "20px"
        }}
      >
        <p
          style={{
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 1,
            color: Palette.nightBlue
          }}
        >
          Régulation transports
        </p>

        <div className="cursor-pointer" onClick={setSubNavBarIsOpen}>
          <img
            width={20}
            height={20}
            src="icons/transport_tracking/close.svg"
            alt=""
          />
        </div>
      </div>
      <div className="my-3">
        <TransportRegulationDropDown
          isDropDownOpen={isDropDownOpen}
          setIsDropDownOpen={setIsDropDownOpen}
          containerStyle={{}}
          selected={selected}
          setSelected={setSelected}
          data={data}
        />
      </div>
      <div>
        <TransportRegulationFilter
          handleFilter={handleFilter}
          handleDateFilter={handleDateFilter}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          filterState={filterState}
          OpenDropDown={() => setIsDropDownOpen((prev) => !prev)}
        />
      </div>
      <div
        className={"container flex"}
        style={{
          height: "410px",
          overflowY: "auto",
          scrollbarColor: "transparent transparent",
          scrollbarWidth: "none"
        }}
      >
        <TransportRegulationList
          selected={selected}
          transports={transportLists
            .filter((item) => {
              return selected.some((selection) => item.text === selection.name);
            })
            .map((item) =>
              item.data.filter((item) => {
                return searchFilter.carType === ""
                  ? moment(item.startDate).isSame(filterState[2], "day")
                  : moment(item.startDate).isSame(filterState[2], "day") &&
                      item.carType === searchFilter.carType;
              })
            )
            .flat()}
        />
      </div>
    </div>
  );
}

export default TransportRegulation;
