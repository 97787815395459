import { CardDescription, CardTitle } from "@components/shadcn/ui/card";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import TexteScroller from "./TexteScroller";
function PatientCard2({ patient, from, to }) {
  const {
    birthDate = "",
    firstName = "",
    lastName = "",
    id = "",
    gender = ""
  } = patient ?? {};

  const navigate = useHistory();
  const handleClick = () => {
    navigate.push(`?patient=${id}`);
  };

  // Current date
  const currentDate = moment();

  // Calculate age
  const age = currentDate.diff(birthDate, "years");
  const title = gender === "female" ? "Mme" : "Mr";
  return (
    <div className="flex flex-row m-3">
      <div className="w-11/12">
        <div className="flex flex-row mb-[0px]">
          <div className="w-1/12 flex flex-row justify-start items-center">
            <img
              src="/icons/regulation_modal/patient.svg"
              alt=""
              className="w-[25px]"
            />
          </div>
          <div className="w-11/12">
            <CardTitle className="h-auto text-nightBlue font-bold text-[14px]">
              <TexteScroller>
                <span className="!text-pink pl-3 italic">{title}</span> {firstName}{" "}
                {lastName}
              </TexteScroller>
            </CardTitle>
            <CardDescription className="h-auto text-lightBlue leading-3 pb-1 font-bold pl-3 italic">
              Né le
              {` ${moment(birthDate).format("M/D/YYYY")} (${age}ans)`}
            </CardDescription>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="leading-1 text-[12px] text-lightBlue">
            {from?.address} - {from?.postalCode} {from?.city}
          </div>
          <div className="leading-1 text-[12px] text-pink">{from?.establishment} {from?.chamber}</div>
        </div>
      </div>
      <div className="w-1/12 flex justify-end">
        <div onClick={handleClick} className="cursor-pointer">
          <img width={17} src="/icons/veicule_info_modal/transport_info.svg" />
        </div>
      </div>
    </div>
  );
}

export default PatientCard2;
