import Button from "@components/Button";
import MyDropdownSelector from "@components/Filters/MyDropdownSelector";
import vehicles from "@constants/vehicles";
import { dataDropdown } from "@helpers/dataDropdown";
import { notificationTypes } from "@helpers/notifications";
import { useZustandStore } from "@helpers/store";
import useDataFromRef from "@hooks/useDataFromRef";
import { useAuth } from "@providers/Auth";
import firebase, { Groups, Notifications } from "@services/Firebase";
import Fonts from "@styles/fonts";
import Palette from "@styles/Palette";
import React, { useEffect, useMemo, useState } from "react";
import {
  RiCalendarLine,
  RiCheckDoubleFill,
  RiFlag2Line,
  RiMapPin2Line,
} from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { useHistory } from "react-router-dom";
const Drawer = ({ isOpen, onClose }) => {
  const setTransportsFromNotifications = useZustandStore(
    (state) => state.setTransportsFromNotifications
  );
  const { user, notifications: notificationsData } = useAuth();
  const { dataNotificationStatus, dataNotificationStatusHospital } =
    dataDropdown;
  const { transportNotifications } = notificationTypes;
  const history = useHistory();
  const [selected, setSelected] =
    user?.role === "HOSPITAL"
      ? useState(dataNotificationStatusHospital[0])
      : useState(dataNotificationStatus[0]);
  const { data: groupsData } = useDataFromRef({
    ref: Groups,
    initialState: [],
    listener: true,
    format: (data) => {
      return data.map((doc) => {
        return {
          ...doc,
          id: doc.id,
        };
      });
    },
  });

  const notifications = useMemo(() => {
    let tmp = notificationsData;
    if (selected.value[0] === "ALL") {
      return tmp;
    }
    if (selected) {
      tmp = tmp.filter((n) => selected.type.includes(n.type));
    }
    return tmp;
  }, [selected, notificationsData]);

  // const transportsWithNotifTime = notifications.map((item) => {
  //   return {
  //     ...item.data.transport,
  //     notifCreatedAt: item.createdAt,
  //     notifType: item.type
  //   };
  // });
  // useEffect(() => {
  //   setTransportsFromNotifications(transportsWithNotifTime);
  // }, [notifications]);

  const AllRead = () => {
    // i want to add the user id to the notReadBy array
    notifications.forEach((notification) => {
      try {
        Notifications.doc(notification.id).update({
          notReadBy: firebase.firestore.FieldValue.arrayRemove(user.id),
        });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    });
  };

  if (!isOpen) return null;
  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        zIndex: 2,
        right: 400,
        top: 100,
        width: 320,
        height: "80%",
        borderRadius: 25,
        background: "#fff",
        boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
        flexDirection: "column",
        padding: 16,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <h2
          style={{
            ...Fonts.semibold(20),
            color: Palette.darkBlue,
          }}
        >
          Notifications
        </h2>
        <button
          onClick={onClose}
          style={{
            cursor: "pointer",
          }}
        >
          <RxCross2 style={{ width: 24, height: 24 }} />
        </button>
      </div>
      <div style={{ marginBottom: 16 }}>
        <MyDropdownSelector
          containerStyle={{}}
          {...{
            selected,
            setSelected,
            data:
              user?.role === "HOSPITAL"
                ? dataNotificationStatusHospital
                : dataNotificationStatus,
          }}
        />
      </div>
      <div
        className="hideScrollbars"
        style={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        {notifications && notifications.length > 0 ? (
          notifications.map((notification) => (
            <div
              className="buttonHover"
              key={notification.id}
              style={{
                border: "1px solid #F6F7FF",
                padding: 16,
                borderRadius: 8,
                marginBottom: 4,
              }}
              onClick={() => {
                history.push(`?transport=${notification?.data.transportId}`);
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    marginRight: 8,
                    marginBottom: 8,
                    alignContent: "center",
                  }}
                >
                  {vehicles[notification.data.transport?.carType] &&
                  vehicles[notification.data.transport?.carType].icon ? (
                    vehicles[notification.data.transport?.carType]?.icon({
                      size: 28,
                      color: Palette.primary,
                    })
                  ) : (
                    <>
                      <span>Icône N/A</span>
                    </>
                  )}
                  <div
                    style={{
                      height: 28,
                      width: 28,
                      borderRadius: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor:
                        transportNotifications[notification.type]
                          ?.backgroundColor,
                    }}
                  >
                    {transportNotifications[notification.type] &&
                    transportNotifications[notification.type].icon ? (
                      transportNotifications[notification.type].icon({
                        size: 20,
                        color: transportNotifications[notification.type]?.color,
                        zIndex: 1,
                      })
                    ) : (
                      <span>Icône N/A</span>
                    )}
                  </div>
                </div>
                <div>
                  <div style={{ marginBottom: 5 }}>
                    <strong
                      style={{
                        color: transportNotifications[notification.type]?.color,
                      }}
                    >
                      {transportNotifications[notification.type]?.title}
                    </strong>{" "}
                    {/* - {notification.data.transport.patient}{" "} */}-{" "}
                    {notification?.data.transport?.patient?.gender === "male"
                      ? "Mr"
                      : "Mme"}{" "}
                    {notification?.data.transport?.patient?.firstName
                      ? notification.data.transport.patient.firstName.charAt(0)
                      : ""}
                    {"."}
                    {notification?.data.transport?.patient?.lastName || ""}
                    {transportNotifications[notification?.type] && (
                      <div
                        style={{
                          fontStyle: "italic",
                          fontWeight: 600,
                          color: "#777",
                          fontSize: 12,
                        }}
                      >
                        <span>
                          {transportNotifications[notification?.type]?.text}
                        </span>{" "}
                        {transportNotifications[notification?.type].value ===
                          "TRANSPORT_BOURSE" && (
                          <strong style={{ color: Palette.red }}>
                            {notification?.data?.bourse?.bourseType === "PUBLIC"
                              ? "Hostal"
                              : groupsData.find(
                                  (group) =>
                                    group.id ===
                                    notification?.data?.bourse?.bourseGroup
                                )?.name}
                          </strong>
                        )}
                        {transportNotifications[notification?.type].driver ===
                          true &&
                          ["ASSIGNED", "ENDED"].includes(
                            transportNotifications[notification?.type].status
                          ) && (
                            <strong style={{ color: Palette.red }}>
                              {notification?.data.driver?.name}
                            </strong>
                          )}
                        {transportNotifications[notification?.type].value ===
                          "TRANSPORT_CANCELED" && (
                          <strong style={{ color: Palette.red }}>
                            {notification?.data.transport?.cancelBy}
                          </strong>
                        )}
                        {transportNotifications[notification?.type].value ===
                          "TRANSPORT_IN_PROGRESS_TO_PATIENT" && (
                          <strong style={{ color: Palette.red }}>
                            {notification?.data.transport?.patient?.firstName +
                              " " +
                              notification?.data.transport?.patient?.lastName}
                          </strong>
                        )}
                        {transportNotifications[notification?.type].value ===
                          "TRANSPORT_IN_PROGRESS_TO_PLACE" && (
                          <strong style={{ color: Palette.red }}>
                            {notification?.data.transport?.to?.name}
                          </strong>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      color: "#777",
                      fontSize: 12,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <RiCalendarLine size={12} style={{ marginRight: 5 }} />
                      <span>
                        {notification.startDate}
                        {/* {moment(notification.startDate).format("DD MMMM YYYY") +
                          " à " +
                          moment(notification.startDate).format("HH:mm")} */}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <RiMapPin2Line size={12} style={{ marginRight: 5 }} />
                      <span>
                        {notification?.data.transport?.from.postalCode}
                        {" - "} {notification?.data.transport?.from.city}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <RiFlag2Line size={12} style={{ marginRight: 5 }} />
                      <span>
                        {notification?.data.transport?.to.postalCode}
                        {" - "} {notification?.data.transport?.to.city}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No notifications available</div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: 16,
          // borderTop: "1px solid #ddd",
          gap: 20,
        }}
      >
        <Button
          onClick={onClose}
          style={{
            padding: "8px 16px",
            border: "none",
            cursor: "pointer",
          }}
          variant="secondary"
          text={"Fermer"}
        />
        <Button
          onClick={AllRead}
          style={{
            padding: "8px 16px",
            border: "none",
            cursor: "pointer",
          }}
          IconLeft={RiCheckDoubleFill}
          iconStyle={{ height: 32, width: 32 }}
          text="Tout marquer comme lu"
        />
      </div>
    </div>
  );
};

export default Drawer;
